<tr *ngFor="let purchasingGroup of purchasingGroups">
  <td>
    <ts-guid-ui [guid]="purchasingGroup.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="purchasingGroup"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <ts-supplier-mention-ui
      [supplier]="purchasingGroup.supplier"
    ></ts-supplier-mention-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: purchasingGroup }"
    ></ng-template>
  </td>
</tr>
