import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import { I18nService } from '@ts/shared/18n/util-core';
import { syncStringPromise } from '@ts/shared/util-async';

import { SubmitButtonConfig } from '../submit-button.model';

@Component({
  selector: 'ts-form-submit-button-ui',
  templateUrl: './form-submit-button-ui.component.html',
  styleUrls: ['./form-submit-button-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSubmitButtonUiComponent implements OnChanges {
  @Input() isInheritStyle = false;
  @Input() isLoading!: boolean;
  @Input() isSubmittable!: boolean;

  @Input() submitButtonConfig!: SubmitButtonConfig;

  submitDisplayedText?: Promise<string>;
  constructor(private i18nService: I18nService) {}

  ngOnChanges() {
    if (this.submitButtonConfig.text) {
      this.submitDisplayedText = syncStringPromise(
        this.submitButtonConfig.text,
      );
    } else {
      // We only display the default text if neither text nor icon was provided
      if (!this.submitButtonConfig.ionIconName) {
        this.submitDisplayedText = this.i18nService.translate$(
          'sharedForm.formSubmit',
        );
      }
    }
  }

  getIonButtonClasses(): readonly string[] {
    const classes: string[] = [];
    if (this.isInheritStyle) {
      classes.push('ion-button--inherited');
    }
    return classes;
  }
}
