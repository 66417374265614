import { Environment } from './environment.model';

// staging is actually "test" server right now.
export const environment: Environment = {
  production: true,
  logLevel: 'debug',

  app: 'soladmin',

  // we still use dev server here for staging until we get a proper
  // separation between "test" server and a "staging" server.
  bormaDagoApiUrl: 'https://dev.bormadago.com',

  soluiUrl: 'https://staging.bormadago.com',

  sentryDsn:
    'https://86557c51771f43ed998f05fd9c343245@o511135.ingest.sentry.io/4504433905041408',
  sentryTracesSampleRate: 0.1,

  googleMapsApiKey: 'AIzaSyAu6Icw1mphXtfesLOby-5W8F5xNaEyV8c',

  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=com.bormadago.app',

  appStoreUrl: '',

  recaptchaSiteKey: '6LcxZPQiAAAAADvcoS_iMcOXqJC9FWDBQoRwZBW_',

  firebaseConfig: {
    apiKey: 'AIzaSyCzocmRy5tWv8ZDHQcqXNY7IXse1sFylPE',
    appId: '1:519473055792:web:1822b785cc30acccaac5f5',
    projectId: 'soladmin-staging',
    measurementId: 'G-WL0NM6P6CT',
  },

  dokuJokulUrl: '',
};
