import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GuidUiComponent } from './guid-ui/guid-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [GuidUiComponent],
  exports: [GuidUiComponent],
})
export class SharedGuidUiCoreModule {}
