<ts-searchbar (searched)="searched($event)"></ts-searchbar>

<div *ngIf="filterConfig" class="filter">
  <!--
    TODO(ivan): Set initial value. Not yet done because
    some of the values are not straightforward to just pass in,
    e.g., Money.
  -->
  <ts-form-flexible-generic-ui
    [config]="filterConfig.formFlexibleConfig"
    [formGenericConfig]="filterConfig.formGenericConfig"
    (submitted)="filtered($event)"
  >
  </ts-form-flexible-generic-ui>
</div>

<table>
  <thead>
    <ng-content select="[slot=header]"></ng-content>
  </thead>
  <tbody>
    <!--
      We use *ngFor as a trick to ensure that
      whenever showMoreCallback$ is changed, the inner component is recreated
    -->
    <ts-pagination-infinite
      [showMoreCallback$]="callback$"
      *ngFor="let callback$ of [showMoreCallback$]"
    >
      <ng-template let-paging="paging" tsPaginationInfiniteTemplate>
        <ng-template
          [ngTemplateOutlet]="pagingTemplate"
          [ngTemplateOutletContext]="{ paging: paging }"
        >
        </ng-template>
      </ng-template>
    </ts-pagination-infinite>
  </tbody>
</table>
