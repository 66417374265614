import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EnvironmentService } from '@ts/shared/util-environment';

import { RouterUrlAbstractService } from './router-url.abstract.service';

@Injectable({
  providedIn: 'root',
})
export class RouterUrlSoluiService extends RouterUrlAbstractService {
  baseUrl: string;

  constructor(router: Router, environmentService: EnvironmentService) {
    super(router);
    this.baseUrl = environmentService.get().soluiUrl;
  }
}
