<tr *ngFor="let liability of liabilities">
  <td>
    <div>
      {{ liability.title }}
    </div>
    <ts-guid-ui [guid]="liability.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="liability"
    ></ts-date-created-updated-ui>
  </td>
  <td><ts-money-ui [money]="liability.amount"></ts-money-ui></td>
  <td>
    <ts-supplier-mention-ui
      [supplier]="liability.supplier"
    ></ts-supplier-mention-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: liability }"
    ></ng-template>
  </td>
</tr>
