import { map, Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { Injectable } from '@angular/core';
import { Category } from '@ts/category/shared/util-core';
import { BormaDagoApiConsumerService } from '@ts/shared/api/data-access-borma-dago-api-consumer';
import { ApiEntityStreamAbstractService } from '@ts/shared/data/data-access-api-entity-stream';
import { RouteParamsService } from '@ts/shared/router/data-access-params';

/**
 * Streams the list of ancestors for the current category.
 *
 * The ancestors are given in order from top to bottom,. e.g.,
 * `[a001, a001-01, a001-01-01]`
 */
@Injectable({
  providedIn: 'root',
})
export class CategoryAncestorsStreamService extends ApiEntityStreamAbstractService<
  readonly Category[]
> {
  getAncestorsUrl = 'api/category/%d/ancestors/';

  constructor(
    protected override apiService: BormaDagoApiConsumerService,
    private routeParamsService: RouteParamsService,
  ) {
    super();
  }

  override get relativeUrl$(): Observable<string> {
    return this.routeParamsService
      .getRouteParam$('categoryId')
      .pipe(map((categoryId) => sprintf(this.getAncestorsUrl, categoryId)));
  }
}
