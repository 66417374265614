<div *ngIf="date_created_timestamp">
  <ng-container *transloco="let t; scope: 'sharedDateTime'">
    {{ t('sharedDateTime.label.created') }}:
  </ng-container>
  <ts-timestamp-ui [timestamp]="date_created_timestamp"></ts-timestamp-ui>
</div>
<div *ngIf="date_updated_timestamp">
  <ng-container *transloco="let t; scope: 'sharedDateTime'">
    {{ t('sharedDateTime.label.updated') }}:
  </ng-container>
  <ts-timestamp-ui [timestamp]="date_updated_timestamp"></ts-timestamp-ui>
</div>
