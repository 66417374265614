import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { isEqual } from 'lodash';

import { Liability } from '@ts/finance/liability/shared/util-core';

@Component({
  selector: 'ts-liability-select-ui',
  templateUrl: './liability-select-ui.component.html',
  styleUrls: ['./liability-select-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiabilitySelectUiComponent {
  @Input() initial?: Liability;
  @Output() selected = new EventEmitter<Liability>();

  isDisabled(value: Liability): boolean {
    return isEqual(this.initial, value);
  }
}
