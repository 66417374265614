import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Supplier } from '@ts/supplier/shared/util-core';

@Component({
  selector: 'ts-supplier-mention-ui',
  templateUrl: './supplier-mention-ui.component.html',
  styleUrls: ['./supplier-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierMentionUiComponent {
  @Input() supplier!: Supplier;
}
