import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import { DateTimeI18nService, Timestamp } from '@ts/shared/date-time/util-core';

@Component({
  selector: 'ts-timestamp-ui',
  templateUrl: './timestamp-ui.component.html',
  styleUrls: ['./timestamp-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimestampUiComponent implements OnChanges {
  @Input() timestamp!: Timestamp;

  timestampMilliseconds!: number;
  timeDisplay!: string;

  constructor(private dateTimeService: DateTimeI18nService) {}

  ngOnChanges() {
    this.timestampMilliseconds = Math.trunc(this.timestamp / 1000);
    this.timeDisplay = this.dateTimeService.timestampDisplay(this.timestamp);
  }
}
