import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { Transport } from '@ts/transport/shared/util-core';

@Component({
  selector: 'ts-transport-table-body-ui',
  templateUrl: './transport-table-body-ui.component.html',
  styleUrls: ['./transport-table-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportTableBodyUiComponent {
  @Input() transports!: readonly Transport[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<Transport>>;
}
