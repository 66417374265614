import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { isEqual } from 'lodash';

import { Money } from '@ts/shared/money/util-core';

@Component({
  selector: 'ts-price-inline-ui',
  templateUrl: './price-inline-ui.component.html',
  styleUrls: ['./price-inline-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceInlineUiComponent implements OnInit {
  @Input() price!: Money;
  @Input() priceObsolete?: Money;

  isPriceObsoleteExist = false;

  ngOnInit() {
    if (this.priceObsolete) {
      this.isPriceObsoleteExist = !isEqual(this.price, this.priceObsolete);
    }
  }

  getClasses(): readonly string[] {
    const classes = ['price'];
    if (this.isPriceObsoleteExist) {
      classes.push('price--discounted');
    }
    return classes;
  }
}
