import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { ModalService } from '@ts/shared/modal/util-core';

// No storybook for this component because requires some kind of modal.
// Please use `CategoryIdFormFieldComponent` as reference instead.

@Component({
  selector: 'ts-form-field-select-with-modal-ui',
  templateUrl: './form-field-select-with-modal-ui.component.html',
  styleUrls: ['./form-field-select-with-modal-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldSelectWithModalUiComponent<T> implements OnInit {
  /**
   * Takes the current entity as input and should output the params that needs to be used
   * to call `ModalService.show$()`.
   */
  @Input() modalShowParamsCallback!: (params: {
    entity?: T;
  }) => Parameters<ModalService['show$']>[0];

  /**
   * What's the key name in the output dictionary that contains the value?
   */
  @Input() modalOutputPropertyName!: string;

  @Input() initial?: T;

  /**
   * If given, will also set the value of the form control to be the entity.
   * If not given, the caller will have to do this manually using the `entitySelected` output.
   */
  @Input() formControl?: FormControl;

  /**
   * Emits the latest entity selected, or null if the user opts to clear it.
   */
  @Output() entitySelected = new EventEmitter<T | null>();

  @ContentChild('entityTemplate') entityTemplate!: TemplateRef<{ entity: T }>;

  entityCurrent?: T;

  constructor(
    private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.entityCurrent = this.initial;
  }

  private async processModal$(modalPromise: Promise<HTMLIonModalElement>) {
    const modal = await modalPromise;
    const { data } = await modal.onDidDismiss();
    if (data && data[this.modalOutputPropertyName] !== undefined) {
      const entityOutput = data[this.modalOutputPropertyName];
      this.entityCurrent = entityOutput ?? undefined;
      this.entitySelected.emit(entityOutput);

      if (this.formControl) {
        this.formControl.setValue(entityOutput ?? null);
      }

      this.changeDetectorRef.detectChanges();
    }
  }

  search() {
    this.processModal$(
      this.modalService.show$(
        this.modalShowParamsCallback({ entity: this.entityCurrent }),
      ),
    );
  }

  clear() {
    this.entitySelected.emit(null);
    if (this.formControl) {
      this.formControl.setValue(null);
    }

    this.entityCurrent = undefined;
    this.changeDetectorRef.detectChanges();
  }
}
