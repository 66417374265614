<div class="title__container">
  <ts-link-ui (click)="clickShowContent()">
    <ion-icon name="caret-forward-outline" [hidden]="showContent"></ion-icon>
    <ion-icon name="caret-down-outline" [hidden]="!showContent"></ion-icon>
    {{ title }}
  </ts-link-ui>
  <ng-content select="[slot=titleEnd]"></ng-content>
</div>
<div [ngClass]="getClasses()">
  <div *ngIf="isLoaded" class="content">
    <ng-template [ngTemplateOutlet]="template"></ng-template>
  </div>
</div>
