<form [formGroup]="formWrapper.formGroup" (ngSubmit)="submitPressed()">
  <ts-ion-form-non-field-errors-ui [errors]="formWrapper.formGroup.errors">
  </ts-ion-form-non-field-errors-ui>

  <ng-content></ng-content>

  <!-- This input is hidden from user and only here to enable
    "submit on enter" functionality -->
  <input
    type="submit"
    class="submit-enter"
    [disabled]="!formWrapper.isSubmittable()"
  />
</form>
