import { Directive, TemplateRef } from '@angular/core';

import { PaginationInfiniteTemplateDirectiveContext } from '@ts/shared/pagination/infinite/util-core';

@Directive({
  selector: '[tsPaginationInfiniteTemplate]',
})
export class PaginationInfiniteTemplateDirective<T> {
  constructor(
    public template: TemplateRef<PaginationInfiniteTemplateDirectiveContext<T>>,
  ) {}
}
