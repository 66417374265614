import { filter, map, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterEventsService {
  urlChanges$!: Observable<NavigationEnd>;

  constructor(router: Router) {
    this.urlChanges$ = router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      // this is a no-op but required to make typescript type checking works
      map((event) => event as NavigationEnd),
    );
  }
}
