<tr *ngFor="let facility of facilities">
  <td>
    <ts-facility-mention-ui [facility]="facility"></ts-facility-mention-ui>
    <ts-date-created-updated-ui
      [instance]="facility"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    {{ facility.code }}
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: facility }"
    >
    </ng-template>
  </td>
</tr>
