import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { PricePurchaseRecord } from '@ts/price/purchase/shared/util-core';
import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import {
  ApiEntityPagingAbstractService,
  EntityPagingService,
} from '@ts/shared/pagination/data-access-entity';

@Injectable({
  providedIn: 'root',
})
export class PricePurchaseRecordPagingService extends ApiEntityPagingAbstractService<PricePurchaseRecord> {
  relativeUrl$ = of('api/price/purchase/staff/record-search/');

  constructor(
    protected override apiService: BormaDagoAuthenticatedApiConsumerService,
    protected override entityPagingService: EntityPagingService,
  ) {
    super();
  }
}
