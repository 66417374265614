import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { BormaDagoApiConsumerService } from '@ts/shared/api/data-access-borma-dago-api-consumer';

export interface ProductSearchAutocompleteParams {
  query: string;
}

/**
 * Provides autocomplete suggestions for searching products.
 */
@Injectable({
  providedIn: 'root',
})
export class ProductSearchAutocompleteService {
  relativeUrl = '/api/search/autocomplete/autocomplete/';
  queryParamKey = 'q';

  constructor(private apiService: BormaDagoApiConsumerService) {}

  autocomplete$({
    query,
  }: ProductSearchAutocompleteParams): Observable<readonly string[]> {
    const queryParams: Record<string, string> = {};
    queryParams[this.queryParamKey] = query;

    if (query === '') {
      return of([]);
    } else {
      return this.apiService.get$<readonly string[]>({
        relativeUrl: this.relativeUrl,
        queryParams,
      });
    }
  }
}
