import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';
import { TransportLaneUiMentionModule } from '@ts/transport/lane/ui-mention';

import { TransportLaneFormFieldComponent } from './transport-lane-form-field/transport-lane-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'transport-lane',
          component: TransportLaneFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    TransportLaneUiMentionModule,
  ],
  declarations: [TransportLaneFormFieldComponent],
  exports: [TransportLaneFormFieldComponent],
})
export class TransportLaneFormFeatureFieldModule {}
