<tr *ngFor="let supplier of suppliers">
  <td>
    <div>{{ supplier.name }}</div>
    <div class="slug">{{ supplier.slug }}</div>
    <ts-date-created-updated-ui
      [instance]="supplier"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    {{ supplier.address }}
  </td>
  <td>
    <div class="contact">
      <ion-icon name="mail"></ion-icon>
      {{ supplier.email }}
    </div>
    <div class="contact">
      <ion-icon name="call"></ion-icon>{{ supplier.phone_number }}
    </div>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: supplier }"
    >
    </ng-template>
  </td>
</tr>
