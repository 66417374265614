import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { IonContent } from '@ionic/angular';
import { ScrollService } from '@ts/shared/util-scroll';

export interface ContentUiConfig {
  isModal: boolean;
}

@Component({
  selector: 'ts-content-ui',
  templateUrl: './content-ui.component.html',
  styleUrls: ['./content-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentUiComponent implements AfterViewInit, OnDestroy {
  @Input() config!: ContentUiConfig;

  @ViewChild(IonContent) ionContent!: IonContent;

  constructor(private scrollService: ScrollService) {}

  ngAfterViewInit() {
    this.scrollService.ionContentAdd(this.ionContent);
  }

  ngOnDestroy() {
    this.scrollService.ionContentDestroy(this.ionContent);
  }

  getClass(): string {
    return this.config.isModal ? 'modal-container' : '';
  }
}
