import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FacilityZoneUiMentionModule } from '@ts/facility/zone/ui-mention';

import { TransportLaneMentionUiComponent } from './transport-lane-mention-ui/transport-lane-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, FacilityZoneUiMentionModule],
  declarations: [TransportLaneMentionUiComponent],
  exports: [TransportLaneMentionUiComponent],
})
export class TransportLaneUiMentionModule {}
