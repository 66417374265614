import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { strict as assert } from 'assert';

import { Promo } from '@ts/promo/shared/util-core';
import { PromoProductLine } from '@ts/promo/shared/util-product-line';

@Component({
  selector: 'ts-promo-num-uses-remaining-ui',
  templateUrl: './promo-num-uses-remaining-ui.component.html',
  styleUrls: ['./promo-num-uses-remaining-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoNumUsesRemainingUiComponent implements OnInit {
  @Input() promo?: Promo;
  @Input() promoProductLine?: PromoProductLine;

  numUsesRemaining: number | null = null;

  ngOnInit() {
    assert(!!this.promo !== !!this.promoProductLine);
    if (this.promo) {
      if (this.promo.type === 'voucher') {
        this.numUsesRemaining = this.promo.num_uses_remaining;
      }
    } else {
      assert(this.promoProductLine);
      if (this.promoProductLine.type === 'discount') {
        this.numUsesRemaining = this.promoProductLine.num_uses_remaining;
      }
    }
  }
}
