import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { checkoutReducer } from './state/checkout.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('checkoutState', checkoutReducer),
  ],
})
export class CheckoutDataAccessModule {}
