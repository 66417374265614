<ts-pagination-infinite-table [pagingService]="userPagingStaffService">
  <ts-user-table-staff-header-ui slot="header"></ts-user-table-staff-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-user-table-staff-body-ui
      [users]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-user-table-staff-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
