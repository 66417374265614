import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { SharedFormWrapperUiIonFormFieldModule } from '@ts/shared/form/wrapper/ui-ion-form-field';

import { CardWrapperUiComponent } from './card-wrapper-ui/card-wrapper-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormWrapperUiIonFormFieldModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'card',
          component: CardWrapperUiComponent,
        },
      ],
    }),
  ],
  declarations: [CardWrapperUiComponent],
  exports: [CardWrapperUiComponent],
})
export class SharedFormWrapperUiCardModule {}
