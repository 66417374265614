import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FileValueAccessorUiDirective } from './file-value-accessor-ui/file-value-accessor-ui.directive';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [FileValueAccessorUiDirective],
  exports: [FileValueAccessorUiDirective],
})
export class SharedFormUiFileModule {}
