import { Injectable } from '@angular/core';

import {
  filter,
  from,
  map,
  MonoTypeOperatorFunction,
  pipe,
  race,
  raceWith,
  take,
} from 'rxjs';

import { ModalController, ModalOptions } from '@ionic/angular';
import { RouterEventsService } from '@ts/shared/router/util-events';
import { ComponentRef } from '@ts/shared/util-ionic';

type ModalSize = 'full' | 'fit-content';

export type ShowModalParams<T extends ComponentRef> = ModalOptions<T> & {
  size?: ModalSize;
  backdropDismiss: boolean;
};

/**
 * Wrapper for Ion ModalController, for showing modals.
 *
 * May overrides default values for the modals.
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private modalController: ModalController,
    private routerEventsService: RouterEventsService,
  ) {}

  /**
   * Returns a modal that's already been presented.
   * You can dismiss it by using `.dismiss()` on the returned modal.
   *
   * @example
   *
   * ```
   * const modal = await modalService.show$({component: ...});
   * modal.dismiss();
   * ```
   */
  async show$<T extends ComponentRef>(
    params: ShowModalParams<T>,
  ): Promise<HTMLIonModalElement> {
    const cssClasses = ['modal-full-height'];
    if (params.size) {
      cssClasses.push(`modal-size-${params.size}`);
      delete params.size;
    }

    const modal = await this.modalController.create({
      cssClass: cssClasses,
      ...params,
    });
    await modal.present();

    // Dismiss the modal when user navigates. To prevent memory leak,
    // we also "unsubscribes" if the modal got dismissed early.
    race([from(modal.onDidDismiss()), this.routerEventsService.urlChanges$])
      .pipe(take(1))
      .subscribe(() => {
        modal.dismiss();
      });

    return modal;
  }

  /**
   * rxjs operator that races the observable with the modal dismiss observable.
   *
   * If the modal dismiss observable win the race, the observable will complete immediately.
   */
  raceWithModalDismissOperator<T>(
    modal: HTMLIonModalElement,
  ): MonoTypeOperatorFunction<T> {
    return pipe(
      map((object: T) => [object, 'notDismissed' as const]),
      raceWith(
        from(modal.onDidDismiss()).pipe(map(() => 'dismissed' as const)),
      ),
      filter((object) => object !== 'dismissed'),
      map(([object]) => object as T),
    );
  }
}
