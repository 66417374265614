import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedPageUiCardModule } from '@ts/shared/page/ui-card';
import { SharedPageUiContentTextModule } from '@ts/shared/page/ui-content-text';

import { PromoDescriptionUiComponent } from './promo-description-ui/promo-description-ui.component';
import { PromoNameDescriptionUiComponent } from './promo-name-description-ui/promo-name-description-ui.component';
import { PromoNameUiComponent } from './promo-name-ui/promo-name-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    SharedPageUiCardModule,
    SharedPageUiContentTextModule,
  ],
  declarations: [
    PromoNameUiComponent,
    PromoDescriptionUiComponent,
    PromoNameDescriptionUiComponent,
  ],
  exports: [
    PromoNameUiComponent,
    PromoDescriptionUiComponent,
    PromoNameDescriptionUiComponent,
  ],
})
export class PromoUiNameDescriptionModule {}
