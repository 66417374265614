import { sprintf } from 'sprintf-js';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Language } from '@ts/shared/i18n/util-constants';

@Component({
  selector: 'ts-language-icon-ui',
  templateUrl: './language-icon-ui.component.html',
  styleUrls: ['./language-icon-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageIconUiComponent implements OnInit {
  @Input() language!: Language;

  languageIconAssetPathBase = '/assets/i18n/img/language/%s.svg';

  languageIconAssetPath!: string;

  ngOnInit() {
    this.languageIconAssetPath = sprintf(
      this.languageIconAssetPathBase,
      this.language,
    );
  }
}
