import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FinanceLiabilityUiMentionModule } from '@ts/finance/liability/ui-mention';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { FinanceLiabilityFormFieldComponent } from './finance-liability-form-field/finance-liability-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'liability',
          component: FinanceLiabilityFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    FinanceLiabilityUiMentionModule,
  ],
  declarations: [FinanceLiabilityFormFieldComponent],
  exports: [FinanceLiabilityFormFieldComponent],
})
export class FinanceLiabilityFormFeatureFieldModule {}
