import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UserMentionUiComponent } from './user-mention-ui/user-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [UserMentionUiComponent],
  exports: [UserMentionUiComponent],
})
export class UserSharedUiMentionModule {}
