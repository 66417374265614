import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FacilityZone } from '@ts/facility/shared/util-core';
import { FacilityZoneSelectModalComponent } from '@ts/facility/zone/action/feature-select';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-facility-zone-form-field',
  templateUrl: './facility-zone-form-field.component.html',
  styleUrls: ['./facility-zone-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityZoneFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'facilityZone';
  initial?: FacilityZone;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<FacilityZone>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: FacilityZoneSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
