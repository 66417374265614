import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { Supplier } from '@ts/supplier/shared/util-core';

@Component({
  selector: 'ts-supplier-table-staff-body-ui',
  templateUrl: './supplier-table-staff-body-ui.component.html',
  styleUrls: ['./supplier-table-staff-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierTableStaffBodyUiComponent {
  @Input() suppliers!: readonly Supplier[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<Supplier>>;
}
