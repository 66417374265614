<table>
  <thead>
    <ts-price-purchase-record-table-header-ui></ts-price-purchase-record-table-header-ui>
  </thead>
  <tbody>
    <ts-price-purchase-record-table-body-ui
      [pricePurchaseRecords]="pricePurchaseRecords"
      [actionTemplate]="actionTemplate"
    >
    </ts-price-purchase-record-table-body-ui>
  </tbody>
</table>
