import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { CashStorage } from '@ts/finance/cash-storage/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-finance-cash-storage-table-body-ui',
  templateUrl: './finance-cash-storage-table-body-ui.component.html',
  styleUrls: ['./finance-cash-storage-table-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceCashStorageTableBodyUiComponent {
  @Input() cashStorages!: readonly CashStorage[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<CashStorage>>;
}
