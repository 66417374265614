import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { SharedFormUiFloatModule } from '@ts/shared/form/ui-float';
import { SharedFormWrapperUiCardModule } from '@ts/shared/form/wrapper/ui-card';
import { SharedMoneyFormUiFieldModule } from '@ts/shared/money/form/ui-field';

import { PromoBenefitPresetProvider } from './promo-benefit.preset';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlyPresetModule,
    FormlyModule.forChild({
      presets: [
        {
          name: 'promoBenefit',
          config: new PromoBenefitPresetProvider(),
        },
      ],
    }),
    SharedFormWrapperUiCardModule,
    SharedMoneyFormUiFieldModule,
    SharedFormUiFloatModule,
  ],
})
export class PromoBenefitUiFormModule {}
