import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ts-float-form-field-ui',
  templateUrl: './float-form-field-ui.component.html',
  styleUrls: ['./float-form-field-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatFormFieldUiComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  floatFormControl!: FormControl<number | null>;

  ngOnInit() {
    this.floatFormControl = new FormControl<number | null>(null);
    const value = this.formControl.value;
    if (value === undefined || value === null) {
      this.floatFormControl.setValue(null);
    } else {
      this.floatFormControl.setValue(value * 100);
    }
  }

  inputChanged() {
    const value = this.floatFormControl.value;
    if (value === undefined || value === null) {
      this.formControl.setValue(null);
    } else {
      this.formControl.setValue(value / 100);
    }
  }
}
