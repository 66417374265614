import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { SharedFormUiFileModule } from '@ts/shared/form/ui-file';

import { ImageFieldUiComponent } from './image-field-ui/image-field-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlyIonicModule,
    ReactiveFormsModule,
    SharedFormUiFileModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'image',
          component: ImageFieldUiComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [ImageFieldUiComponent],
  exports: [ImageFieldUiComponent],
})
export class SharedFormUiImageModule {}
