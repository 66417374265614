import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { SharedLogoUiCoreModule } from '@ts/shared/logo/ui-core';

import { LogoShopUiComponent } from './logo-shop-ui/logo-shop-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, SharedLogoUiCoreModule],
  declarations: [LogoShopUiComponent],
  exports: [LogoShopUiComponent],
})
export class SharedLogoUiShopModule {}
