import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PurchasingGroup } from '@ts/purchasing/shared/util-core';

@Component({
  selector: 'ts-purchasing-group-mention-ui',
  templateUrl: './purchasing-group-mention-ui.component.html',
  styleUrls: ['./purchasing-group-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingGroupMentionUiComponent {
  @Input() purchasingGroup!: PurchasingGroup;
}
