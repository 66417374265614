<tr *ngFor="let cashStorage of cashStorages">
  <td>
    <div>{{ cashStorage.name }}</div>
    <ts-guid-ui [guid]="cashStorage.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="cashStorage"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <ts-money-ui [money]="cashStorage.amount"></ts-money-ui>
  </td>
  <td>
    {{ cashStorage.type }}
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: cashStorage }"
    ></ng-template>
  </td>
</tr>
