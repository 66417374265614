import { Injectable } from '@angular/core';
import { Timestamp } from '@ts/shared/date-time/util-core';

@Injectable({
  providedIn: 'root',
})
export class TimestampService {
  now(): Timestamp {
    return Date.now() * 1000;
  }
}
