import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { ProductRange } from '@ts/product/shared/util-core';

@Component({
  selector: 'ts-product-range-select-modal',
  templateUrl: './product-range-select-modal.component.html',
  styleUrls: ['./product-range-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRangeSelectModalComponent {
  @Input() productRange!: ProductRange;

  constructor(private modalController: ModalController) {}

  productRangeSelected(productRange: ProductRange) {
    this.modalController.dismiss({ productRange });
  }
}
