import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { TransportLane } from '@ts/transport/shared/util-core';

@Component({
  selector: 'ts-transport-lane-select-modal',
  templateUrl: './transport-lane-select-modal.component.html',
  styleUrls: ['./transport-lane-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportLaneSelectModalComponent {
  @Input() initial?: TransportLane;

  constructor(private modalController: ModalController) {}

  transportLaneSelected(transportLane: TransportLane | null) {
    this.modalController.dismiss({ transportLane });
  }
}
