<ion-header translucent>
  <ion-toolbar>
    <ts-logo-shop-ui logoShopType="logo-only" slot="start"></ts-logo-shop-ui>
    <ion-title>{{ title$ | async }}</ion-title>
    <ion-buttons slot="end">
      <ion-button class="close__ion-button" (click)="closeModal()">
        <ng-container *transloco="let t; scope: 'common'">
          {{ t('common.close') }}
        </ng-container>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ts-content-ui [config]="{ isModal: true }">
  <ng-content></ng-content>
</ts-content-ui>
