<ts-pagination-infinite-table [pagingService]="supplierPagingStaffService">
  <ts-supplier-table-staff-header-ui
    slot="header"
  ></ts-supplier-table-staff-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-supplier-table-staff-body-ui
      [suppliers]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-supplier-table-staff-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
