import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { strict as assert } from 'assert';

import { PromoProductLineDiscount } from '@ts/promo/shared/util-product-line';
import { PriceDiscountInfo } from '@ts/shared/price/util-core';

@Component({
  selector: 'ts-promo-product-line-discount-info-ui',
  templateUrl: './promo-product-line-discount-info-ui.component.html',
  styleUrls: ['./promo-product-line-discount-info-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoProductLineDiscountInfoUiComponent implements OnInit {
  @Input() promoProductLineDiscount!: PromoProductLineDiscount;
  @Input() priceDiscountInfo?: PriceDiscountInfo;

  ngOnInit() {
    if (this.promoProductLineDiscount.discount_quantity_multiples < 2) {
      // if not discount quantity multiple make sure that priceDiscountInfo exist
      assert(this.priceDiscountInfo);
    }
  }
}
