import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { isEqual } from 'lodash';

import { ProductStaff } from '@ts/product/shared/util-core';

@Component({
  selector: 'ts-product-staff-select-ui',
  templateUrl: './product-staff-select-ui.component.html',
  styleUrls: ['./product-staff-select-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductStaffSelectUiComponent {
  @Input() initial?: ProductStaff;
  @Output() selected = new EventEmitter<ProductStaff>();

  isDisabled(value: ProductStaff): boolean {
    return isEqual(this.initial, value);
  }
}
