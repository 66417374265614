import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { FormlyModule } from '@ngx-formly/core';

import { RepeatFormlyTypeUiComponent } from './repeat-formly-type-ui/repeat-formly-type-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'repeat',
          component: RepeatFormlyTypeUiComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    TranslocoModule,
  ],
  declarations: [RepeatFormlyTypeUiComponent],
  exports: [RepeatFormlyTypeUiComponent],
})
export class SharedFormUiRepeatModule {}
