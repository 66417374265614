import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { strict as assert } from 'assert';
import {
  debounceTime,
  Observable,
  Subject,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

@Component({
  selector: 'ts-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchbarComponent implements OnInit, OnDestroy {
  /**
   * If provided, the searchbar will provide user with autocomplete suggestions.
   * This callback should return the autocomplete list, given the query string.
   */
  @Input() autocompleteCallback?: (params: {
    query: string;
  }) => Observable<readonly string[]>;

  /**
   * Emits when the user has finalized the string they wish to search for.
   */
  @Output() searched = new EventEmitter<string>();

  /**
   * How many ms to debounce before we perform the remote query?
   */
  autocompleteDebounceMs = 300;

  /**
   * Stream of queries to be queried into the autocomplete callback machine.
   */
  private autocompleteQueries$ = new Subject<string>();

  /**
   * The actual autocomplete suggestions, or empty list if none exists or still in the process of loading the list.
   */
  autocompleteSuggestions: readonly string[] = [];

  subscription?: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.autocompleteCallback) {
      this.subscription = this.autocompleteQueries$
        .pipe(
          // don't query too often
          debounceTime(this.autocompleteDebounceMs),
          // perform the query
          switchMap((query) => {
            assert(this.autocompleteCallback);

            return this.autocompleteCallback({ query });
          }),
          // put the result into the autocompleteSuggestions variable
          tap((autocompleteSuggestions) => {
            this.autocompleteSuggestions = autocompleteSuggestions;
            this.changeDetectorRef.detectChanges();
          }),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  queryTyped(query: string) {
    this.autocompleteQueries$.next(query);
  }
}
