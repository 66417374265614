import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PromoProductLineDiscount } from '@ts/promo/shared/util-product-line';

@Component({
  selector: 'ts-promo-product-line-discount-quantity-multiple-ui',
  templateUrl:
    './promo-product-line-discount-quantity-multiple-ui.component.html',
  styleUrls: [
    './promo-product-line-discount-quantity-multiple-ui.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoProductLineDiscountQuantityMultipleUiComponent {
  @Input() promoProductLineDiscount!: PromoProductLineDiscount;
}
