import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FinanceCashFlowLaneUiMentionModule } from '@ts/finance/cash-flow/lane/ui-mention';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { FinanceCashFlowLaneFormFieldComponent } from './finance-cash-flow-lane-form-field/finance-cash-flow-lane-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'cash-flow-lane',
          component: FinanceCashFlowLaneFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    FinanceCashFlowLaneUiMentionModule,
  ],
  declarations: [FinanceCashFlowLaneFormFieldComponent],
  exports: [FinanceCashFlowLaneFormFieldComponent],
})
export class FinanceCashFlowLaneFormFeatureFieldModule {}
