<tr *ngFor="let promoStaff of promoStaffs">
  <td>
    {{ promoStaff.name }} <br />
    <ts-language-icon-ui language="id"></ts-language-icon-ui>
    {{ promoStaff.name_id }} <br />
    <ts-language-icon-ui language="en"></ts-language-icon-ui>
    {{ promoStaff.name_en }}
  </td>
  <td>
    <div *ngIf="promoStaff.image_id" class="image">
      <ts-language-icon-ui language="id"></ts-language-icon-ui>
      <ion-img src="{{ promoStaff.image_id }}" /><br />
    </div>
    <div *ngIf="promoStaff.image_en" class="image">
      <ts-language-icon-ui language="en"></ts-language-icon-ui>
      <ion-img src="{{ promoStaff.image_en }}" />
    </div>
  </td>
  <td>
    {{ promoStaff.type }}
    <ng-container *ngIf="promoStaff.type === 'voucher'">
      {{ promoStaff.voucher_type }} <br />
      Code: {{ promoStaff.code }}
    </ng-container>
  </td>
  <td>
    <ts-timestamp-ui
      [timestamp]="promoStaff.date_start_timestamp"
    ></ts-timestamp-ui>
    -
    <ts-timestamp-ui
      [timestamp]="promoStaff.date_end_timestamp"
    ></ts-timestamp-ui>
  </td>
  <td>
    <ng-container *ngIf="promoStaff.enabler">Siap</ng-container>
    <ng-container *ngIf="!promoStaff.enabler">Draft</ng-container>
    <ng-container *ngIf="!promoStaff.is_advertised">
      (Tersembunyi)
    </ng-container>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: promoStaff }"
    >
    </ng-template>
  </td>
</tr>
