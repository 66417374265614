<ts-pagination-infinite-table [pagingService]="priceSellChangePagingService">
  <ts-price-sell-change-table-header-ui
    slot="header"
  ></ts-price-sell-change-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-price-sell-change-table-body-ui
      [priceSellChanges]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-price-sell-change-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
