import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PageContentTextUiComponent } from './page-content-text-ui/page-content-text-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [PageContentTextUiComponent],
  exports: [PageContentTextUiComponent],
})
export class SharedPageUiContentTextModule {}
