import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FacilityMentionUiComponent } from './facility-mention-ui/facility-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [FacilityMentionUiComponent],
  exports: [FacilityMentionUiComponent],
})
export class FacilityUiMentionModule {}
