import { Injectable } from '@angular/core';

import { strict as assert } from 'assert';

import { Money, MoneyOperationService } from '@ts/shared/money/util-core';

import { PriceDiscountInfo } from './price.model';

export interface PriceDiscountParams {
  price: Money;
  discount: Money;
  discountFraction: number;
}

@Injectable({
  providedIn: 'root',
})
export class PriceDiscountService {
  constructor(private moneyOperationService: MoneyOperationService) {}

  calculate({
    price,
    discount,
    discountFraction,
  }: PriceDiscountParams): PriceDiscountInfo {
    assert(price.currency === discount.currency);

    const discountTotalAmount = this.moneyOperationService.moneyAdd(
      this.moneyOperationService.numberToMoney(
        this.moneyOperationService.moneyToNumber(
          this.moneyOperationService.moneySubtract(price, discount),
        ) * discountFraction,
        price.currency,
      ),
      discount,
    );

    const priceWithDiscount = this.moneyOperationService.moneySubtract(
      price,
      discountTotalAmount,
    );

    const discountTotalFraction =
      this.moneyOperationService.moneyToNumber(discountTotalAmount) /
      this.moneyOperationService.moneyToNumber(price);

    return {
      priceWithoutDiscount: price,
      priceWithDiscount,
      discountTotalAmount,
      discountTotalFraction,
    };
  }
}
