<tr *ngFor="let transportLane of transportLanes">
  <td>
    <ts-guid-ui [guid]="transportLane.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="transportLane"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <ts-facility-zone-mention-ui
      [facilityZone]="transportLane.facility_zone_origin"
    ></ts-facility-zone-mention-ui>
  </td>
  <td>
    <ts-facility-zone-mention-ui
      [facilityZone]="transportLane.facility_zone_destination"
    ></ts-facility-zone-mention-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: transportLane }"
    ></ng-template>
  </td>
</tr>
