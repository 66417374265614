import { Injectable } from '@angular/core';

import { PagingMeta } from './pagination.model';

@Injectable({
  providedIn: 'root',
})
export class PaginationUtilService {
  /**
   * Returns what is the index of the final page for a given pagingMeta.
   */
  computeFinalPagingPage(pagingMeta: PagingMeta): number {
    return Math.ceil(pagingMeta.totalEntities / pagingMeta.entitiesPerPage);
  }
}
