import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { User } from '@ts/user/shared/util-core';

@Component({
  selector: 'ts-user-table-staff-ui',
  templateUrl: './user-table-staff-ui.component.html',
  styleUrls: ['./user-table-staff-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTableStaffUiComponent {
  @Input() users!: readonly User[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @ContentChild('actionTemplate') actionTemplate?: TemplateRef<
    ActionTemplateContext<User>
  >;
}
