import { UntypedFormGroup } from '@angular/forms';

import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

import { FormWrapper } from './form-wrapper';

export interface FormWrapperParams<T> {
  fields: readonly FormlyFieldConfig[];
  options?: FormlyFormOptions;
  model?: Partial<T>;
}

/**
 * Indicates that the form field is for creation only and should be excluded if the model is not empty.
 */
export const FORM_FIELD_CREATE_ONLY = 'create_only';

/**
 * Represents a fully functional @ngx-formly/core form.
 *
 * This wraps many formly parameters into one class to make it easier to pass around.
 */
export class FormlyWrapper<T> {
  fields: readonly FormlyFieldConfig[];
  options: FormlyFormOptions;
  model: Partial<T> = {};

  formWrapper = new FormWrapper(new UntypedFormGroup({}));

  constructor({
    fields,
    options = {},
    model = undefined,
  }: Readonly<FormWrapperParams<T>>) {
    this.fields = fields.filter((field) => {
      const isCreateOnly = field.props?.[FORM_FIELD_CREATE_ONLY];
      return !(isCreateOnly && model);
    });
    this.options = options;

    // the model should copy it since it will get modified
    this.model = model ? { ...model } : {};
  }
}
