import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Facility } from '@ts/facility/shared/util-core';

@Component({
  selector: 'ts-facility-select-modal',
  templateUrl: './facility-select-modal.component.html',
  styleUrls: ['./facility-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilitySelectModalComponent {
  @Input() initial?: Facility;

  constructor(private modalController: ModalController) {}

  facilitySelected(facility: Facility | null) {
    this.modalController.dismiss({ facility });
  }
}
