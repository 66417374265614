import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'ts-link-expander-ui',
  templateUrl: './link-expander-ui.component.html',
  styleUrls: ['./link-expander-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkExpanderUiComponent {
  @Input() title!: string;
  showContent = false;
  isLoaded = false;

  @ContentChild(TemplateRef) template!: TemplateRef<unknown>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  clickShowContent(): false {
    this.showContent = !this.showContent;
    this.isLoaded = true;
    this.changeDetectorRef.detectChanges();
    return false;
  }

  getClasses(): readonly string[] {
    const classes = ['content__container'];
    if (!this.showContent) {
      classes.push('content--hidden');
    }
    return classes;
  }
}
