import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { PricePurchaseRecordPagingService } from '@ts/price/purchase/record/list/data-access';
import { PricePurchaseRecord } from '@ts/price/purchase/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-price-purchase-record-list-infinite',
  templateUrl: './price-purchase-record-list-infinite.component.html',
  styleUrls: ['./price-purchase-record-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePurchaseRecordListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<
    ActionTemplateContext<PricePurchaseRecord>
  >;

  constructor(
    public pricePurchaseRecordPagingService: PricePurchaseRecordPagingService,
  ) {}
}
