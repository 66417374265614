<ng-container *ngIf="priceZero; else priceExist">
  <div>
    <ng-container *transloco="let t; scope: 'product'">
      <ion-text color="medium">{{ t('product.notAvailable') }}</ion-text>
    </ng-container>
  </div>
</ng-container>
<ng-template #priceExist>
  <ng-container
    *ngIf="
      displayType === 'table' && priceDiscountInfo && promoProductLineDiscount;
      else priceInline
    "
  >
    <ts-product-price-table-ui
      [priceDiscountInfo]="priceDiscountInfo"
      [promoProductLine]="promoProductLineDiscount"
    ></ts-product-price-table-ui>
  </ng-container>
  <ng-template #priceInline>
    <ng-container
      *ngIf="
        priceDiscountInfo &&
          promoProductLineDiscount &&
          promoProductLineDiscount.discount_quantity_multiples < 2;
        else priceInlineOriginal
      "
    >
      <ts-price-inline-ui
        [price]="priceDiscountInfo.priceWithDiscount"
        [priceObsolete]="priceDiscountInfo.priceWithoutDiscount"
      ></ts-price-inline-ui>
      <ts-promo-product-line-discount-info-ui
        *ngIf="promoProductLineDiscount.num_uses_remaining !== null"
        [promoProductLineDiscount]="promoProductLineDiscount"
        [priceDiscountInfo]="priceDiscountInfo"
      ></ts-promo-product-line-discount-info-ui>
    </ng-container>
    <ng-template #priceInlineOriginal>
      <ts-price-inline-ui *ngIf="price" [price]="price"></ts-price-inline-ui>
      <ng-container *ngIf="promoProductLineDiscount">
        <ts-promo-product-line-discount-info-ui
          [promoProductLineDiscount]="promoProductLineDiscount"
        ></ts-promo-product-line-discount-info-ui>
      </ng-container>
      <ng-container *ngIf="promoProductLineLimit">
        <ts-promo-product-line-limit-info-ui
          [promoProductLineLimit]="promoProductLineLimit"
        ></ts-promo-product-line-limit-info-ui>
      </ng-container>
    </ng-template>
  </ng-template>
</ng-template>
