import { map, Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { Injectable } from '@angular/core';
import { Category } from '@ts/category/shared/util-core';
import { BormaDagoApiConsumerService } from '@ts/shared/api/data-access-borma-dago-api-consumer';
import { ApiEntityStreamAbstractService } from '@ts/shared/data/data-access-api-entity-stream';
import { RouteParamsService } from '@ts/shared/router/data-access-params';

@Injectable({
  providedIn: 'root',
})
export class CategoryStreamService extends ApiEntityStreamAbstractService<Category> {
  relativeUrl = 'api/category/%d/';

  constructor(
    protected override apiService: BormaDagoApiConsumerService,
    private routeParamsService: RouteParamsService,
  ) {
    super();
  }

  override get relativeUrl$(): Observable<string> {
    return this.routeParamsService
      .getRouteParam$('categoryId')
      .pipe(map((categoryId) => sprintf(this.relativeUrl, categoryId)));
  }
}
