import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Product } from '@ts/product/shared/util-core';

@Component({
  selector: 'ts-product-title-ui',
  templateUrl: './product-title-ui.component.html',
  styleUrls: ['./product-title-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTitleUiComponent {
  @Input() product!: Product;
  @Input() hasLink = false;
}
