<div class="container" *transloco="let t; scope: 'promo'">
  <div class="content">
    <ng-container
      *ngIf="
        promoProductLineDiscount.discount_quantity_multiples < 2 &&
          priceDiscountInfo;
        else promoProductLineDiscountQuantityMultiple
      "
    >
      <ts-fraction-as-percentage-ui
        [fraction]="priceDiscountInfo.discountTotalFraction"
      ></ts-fraction-as-percentage-ui>
      {{ t('promo.discount') }}
    </ng-container>
    <ng-template #promoProductLineDiscountQuantityMultiple>
      <ts-promo-product-line-discount-quantity-multiple-ui
        [promoProductLineDiscount]="promoProductLineDiscount"
      ></ts-promo-product-line-discount-quantity-multiple-ui>
    </ng-template>
  </div>
  <ts-promo-num-uses-remaining-ui
    [promoProductLine]="promoProductLineDiscount"
  ></ts-promo-num-uses-remaining-ui>
</div>
