import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { User } from '@ts/user/shared/util-core';

@Component({
  selector: 'ts-user-select-modal',
  templateUrl: './user-select-modal.component.html',
  styleUrls: ['./user-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSelectModalComponent {
  @Input() initial?: User;

  constructor(private modalController: ModalController) {}

  userSelected(user: User | null) {
    this.modalController.dismiss({ user });
  }
}
