import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { CashStorage } from '@ts/finance/cash-storage/shared/util-core';

@Component({
  selector: 'ts-finance-cash-storage-select-modal',
  templateUrl: './finance-cash-storage-select-modal.component.html',
  styleUrls: ['./finance-cash-storage-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceCashStorageSelectModalComponent {
  @Input() initial?: CashStorage;

  constructor(private modalController: ModalController) {}

  cashStorageSelected(cashStorage: CashStorage | null) {
    this.modalController.dismiss({ cashStorage });
  }
}
