import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Facility } from '@ts/facility/shared/util-core';
import { BormaDagoPersistentAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';

@Injectable({
  providedIn: 'root',
})
export class FacilityListService {
  facilityUrl = 'api/facility/staff/facility/all/';

  constructor(
    private apiService: BormaDagoPersistentAuthenticatedApiConsumerService,
  ) {}

  getFacilities$(): Observable<readonly Facility[] | null> {
    return this.apiService.get$({
      relativeUrl: this.facilityUrl,
    });
  }
}
