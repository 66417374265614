import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { SharedLinkFeatureCoreModule } from '@ts/shared/link/feature-core';

import { HrefDirective } from './href/href.directive';
import { LinkUiComponent } from './link-ui/link-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, SharedLinkFeatureCoreModule],
  declarations: [LinkUiComponent, HrefDirective],
  exports: [LinkUiComponent, HrefDirective],
})
export class SharedLinkUiCoreModule {}
