import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  FormFlexibleConfig,
  FormGenericConfig,
} from '@ts/shared/form/flexible/util-core';
import { PlatformService } from '@ts/shared/util-platform';

@Component({
  selector: 'ts-form-flexible-generic-ui',
  templateUrl: './form-flexible-generic-ui.component.html',
  styleUrls: ['./form-flexible-generic-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFlexibleGenericUiComponent<T, U> implements OnInit {
  @Input() config!: FormFlexibleConfig<T, U>;
  @Input() initial?: T;
  @Input() formGenericConfig!: FormGenericConfig;
  @Output() submitted = new EventEmitter<U>();

  isScreenMinWidthLg!: boolean;
  initialLength?: number;

  constructor(private platformService: PlatformService) {}

  ngOnInit() {
    if (this.initial && this.formGenericConfig.type === 'button') {
      this.initialLength = Object.keys(this.initial).length;
    }

    this.isScreenMinWidthLg = this.platformService.isScreenMinWidth('lg');
  }
}
