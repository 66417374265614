import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { Facility } from '@ts/facility/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-facility-table-body-ui',
  templateUrl: './facility-table-body-ui.component.html',
  styleUrls: ['./facility-table-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityTableBodyUiComponent {
  @Input() facilities!: readonly Facility[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<Facility>>;
}
