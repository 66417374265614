import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { LogoUiComponent } from './logo-ui/logo-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [LogoUiComponent],
  exports: [LogoUiComponent],
})
export class SharedLogoUiCoreModule {}
