import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { PricePurchaseRecord } from '@ts/price/purchase/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-price-purchase-record-table-ui',
  templateUrl: './price-purchase-record-table-ui.component.html',
  styleUrls: ['./price-purchase-record-table-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePurchaseRecordTableUiComponent {
  @Input() pricePurchaseRecords!: readonly PricePurchaseRecord[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @ContentChild('actionTemplate') actionTemplate?: TemplateRef<
    ActionTemplateContext<PricePurchaseRecord>
  >;
}
