import { FormlyFieldConfig } from '@ngx-formly/core';

export const PRODUCT_SEARCH_QUERY_PARAMS_FILTER_FORM_FIELDS: FormlyFieldConfig[] =
  [
    {
      key: 'price__gte',
      type: 'money',
      props: {
        i18n: true,
        label: 'filter.priceMin',
      },
    },
    {
      key: 'price__lte',
      type: 'money',
      props: {
        i18n: true,
        label: 'filter.priceMax',
      },
    },
    {
      key: 'is_promo_active',
      type: 'toggle',
      props: {
        i18n: true,
        label: 'filter.isPromoActive',
      },
    },
  ];

export const PRODUCT_SEARCH_STAFF_QUERY_PARAMS_FILTER_FORM_FIELDS: FormlyFieldConfig[] =
  [
    ...PRODUCT_SEARCH_QUERY_PARAMS_FILTER_FORM_FIELDS,
    {
      key: 'category',
      type: 'category-id',
      props: {
        i18n: true,
        label: 'filter.category',
      },
    },
  ];
