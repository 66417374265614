<tr *ngFor="let pricePurchaseChange of pricePurchaseChanges">
  <td>
    <div>{{ pricePurchaseChange.title }}</div>
    <ts-guid-ui [guid]="pricePurchaseChange.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="pricePurchaseChange"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    {{ pricePurchaseChange.status }}
  </td>
  <td>
    <ts-supplier-mention-ui
      [supplier]="pricePurchaseChange.supplier"
    ></ts-supplier-mention-ui>
  </td>
  <td>
    <ts-timestamp-ui
      [timestamp]="pricePurchaseChange.date_start_timestamp"
    ></ts-timestamp-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: pricePurchaseChange }"
    >
    </ng-template>
  </td>
</tr>
