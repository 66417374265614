import { Injectable } from '@angular/core';

import { I18nService } from '@ts/shared/18n/util-core';
import { Timestamp } from '@ts/shared/date-time/util-core';

import { TimeDisplay } from './date-time.model';

@Injectable({
  providedIn: 'root',
})
export class DateTimeI18nService {
  constructor(private i18nService: I18nService) {}

  timestampFormat(timestamp: Timestamp): TimeDisplay {
    const date = new Date(Math.trunc(timestamp / 1000));
    const locale = this.i18nService.getLanguageActive();
    const timezoneParts = date
      .toLocaleTimeString(locale, { timeZoneName: 'short' })
      .split(' ');
    const timezone = timezoneParts[timezoneParts.length - 1];
    return {
      hour: date.getHours().toString().padStart(2, '0'),
      minute: date.getMinutes().toString().padStart(2, '0'),
      second: date.getSeconds().toString().padStart(2, '0'),
      timezone: timezone,
    };
  }

  timestampDisplay(timestamp: Timestamp): string {
    const timeDisplay = this.timestampFormat(timestamp);
    return `${timeDisplay.hour}:${timeDisplay.minute}:${timeDisplay.second} ${timeDisplay.timezone}`;
  }
}
