import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FinanceLiabilityMentionUiComponent } from './finance-liability-mention-ui/finance-liability-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [FinanceLiabilityMentionUiComponent],
  exports: [FinanceLiabilityMentionUiComponent],
})
export class FinanceLiabilityUiMentionModule {}
