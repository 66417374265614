import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { PricePurchaseRecordSelectModalComponent } from '@ts/price/purchase/record/action/feature-select';
import { PricePurchaseRecord } from '@ts/price/purchase/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-price-purchase-record-form-field',
  templateUrl: './price-purchase-record-form-field.component.html',
  styleUrls: ['./price-purchase-record-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePurchaseRecordFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'pricePurchaseRecord';
  initial?: PricePurchaseRecord;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<PricePurchaseRecord>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: PricePurchaseRecordSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
