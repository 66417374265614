<ts-modal-ui title="Pilih Fasilitas">
  <ts-page-card-ui>
    <ts-choices-action-template-ui
      [initial]="initial"
      (selected)="facilitySelected($event)"
    >
      <ng-template #choicesTemplate let-actionTemplate="actionTemplate">
        <ts-facility-table
          [actionTemplate]="actionTemplate"
        ></ts-facility-table>
      </ng-template>
    </ts-choices-action-template-ui>
  </ts-page-card-ui>
</ts-modal-ui>
