import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Transport } from '@ts/transport/shared/util-core';

@Component({
  selector: 'ts-transport-mention-ui',
  templateUrl: './transport-mention-ui.component.html',
  styleUrls: ['./transport-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportMentionUiComponent {
  @Input() transport!: Transport;
}
