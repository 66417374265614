import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { FormFlexibleConfig } from '@ts/shared/form/flexible/util-core';
import { FormlyWrapper } from '@ts/shared/form/util-core';

@Component({
  selector: 'ts-form-flexible-form-ui',
  templateUrl: './form-flexible-form-ui.component.html',
  styleUrls: ['./form-flexible-form-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFlexibleFormUiComponent<T, U = T> implements OnInit {
  @Input() config!: FormFlexibleConfig<T, U>;
  @Input() initial?: T;
  @Output() submitted = new EventEmitter<U>();

  formlyWrapper!: FormlyWrapper<T>;
  // TODO(Farid): should become input because it can be various base on what purpose we using this form
  submitButtonConfig = {
    text: 'Filter',
  };
  isResetButtonShown!: boolean;

  ngOnInit() {
    if (this.initial) {
      if (Object.keys(this.initial).length) {
        this.isResetButtonShown = true;
      } else {
        this.isResetButtonShown = false;
      }
    } else {
      this.isResetButtonShown = false;
    }
    this.formlyWrapper = new FormlyWrapper<T>({
      fields: this.config.fields,
      model: this.initial,
    });
  }

  submit(data: T) {
    if (this.config.transformCallback) {
      this.submitted.emit(this.config.transformCallback(data));
    } else {
      this.submitted.emit(data as unknown as U);
    }
    /**
     * we reenable it directly because we don't call api when this form submitted
     */
    this.formlyWrapper.formWrapper.reenable();
  }

  reset() {
    this.submitted.emit({} as U);
  }
}
