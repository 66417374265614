import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { ApiEntityStreamAbstractService } from '@ts/shared/data/data-access-api-entity-stream';
import { RouteParamsService } from '@ts/shared/router/data-access-params';
import { User } from '@ts/user/shared/util-core';

/**
 * Retrieves the current viewed user's information.
 */
@Injectable({
  providedIn: 'root',
})
export class UserStaffStreamService extends ApiEntityStreamAbstractService<User> {
  url = 'api/user/staff/%d/';

  constructor(
    protected override apiService: BormaDagoAuthenticatedApiConsumerService,
    private routeParamsService: RouteParamsService,
  ) {
    super();
  }

  override get relativeUrl$(): Observable<string> {
    return this.routeParamsService
      .getRouteParam$('userId')
      .pipe(map((userId) => sprintf(this.url, userId)));
  }
}
