import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Facility } from '@ts/facility/shared/util-core';

@Component({
  selector: 'ts-facility-mention-ui',
  templateUrl: './facility-mention-ui.component.html',
  styleUrls: ['./facility-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityMentionUiComponent {
  @Input() facility!: Facility;
}
