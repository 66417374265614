import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FinanceCashStorageUiMentionModule } from '@ts/finance/cash-storage/ui-mention';

import { FinanceCashFlowLaneMentionUiComponent } from './finance-cash-flow-lane-mention-ui/finance-cash-flow-lane-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, FinanceCashStorageUiMentionModule],
  declarations: [FinanceCashFlowLaneMentionUiComponent],
  exports: [FinanceCashFlowLaneMentionUiComponent],
})
export class FinanceCashFlowLaneUiMentionModule {}
