import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';

import { PromoNumUsesRemainingUiComponent } from './promo-num-uses-remaining-ui/promo-num-uses-remaining-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, TranslocoModule],
  declarations: [PromoNumUsesRemainingUiComponent],
  exports: [PromoNumUsesRemainingUiComponent],
})
export class PromoUiNumUsesRemainingModule {}
