<ng-container
  *ngIf="props.options | formlySelectOptions: field | async; let selectOptions"
>
  <ion-list lines="none">
    <ion-item
      button
      *ngFor="let option of selectOptions"
      (click)="optionSelect(option)"
      [ngClass]="getClass(option)"
      [disabled]="formControl.disabled"
    >
      {{ option.label }}
    </ion-item>
    <ion-button
      *ngIf="formControl.value"
      (click)="formControl.setValue(null)"
      fill="clear"
      color="danger"
    >
      <ion-icon name="close-sharp"></ion-icon>
    </ion-button>
  </ion-list>
</ng-container>
