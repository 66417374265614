<tr *ngFor="let user of users">
  <td>
    <ts-user-mention-ui [user]="user"></ts-user-mention-ui>
  </td>
  <td>
    {{ user.email }}
  </td>
  <td>
    {{ user.phone_number }}
  </td>
  <td>
    <ts-language-icon-ui [language]="user.language"></ts-language-icon-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: user }"
    ></ng-template>
  </td>
</tr>
