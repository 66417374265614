import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { mapValues, pickBy } from 'lodash';

import {
  FormFlexibleConfig,
  FormGenericConfig,
} from '@ts/shared/form/flexible/util-core';
import { ApiEntityPagingAbstractService } from '@ts/shared/pagination/data-access-entity';
import { PaginationInfiniteComponent } from '@ts/shared/pagination/infinite/feature-core';
import { Paging } from '@ts/shared/pagination/util-core';

// No storybook, because requires service as input.
// Instead, check ProductStaffListInfiniteComponent, which uses this component.

interface FilterConfig<U, V extends Object> {
  formFlexibleConfig: FormFlexibleConfig<U, V>;
  formGenericConfig: FormGenericConfig;
}

@Component({
  selector: 'ts-pagination-infinite-table',
  templateUrl: './pagination-infinite-table.component.html',
  styleUrls: ['./pagination-infinite-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationInfiniteTableComponent<T, U, V extends Object>
  implements AfterContentInit
{
  @Input() pagingService!: ApiEntityPagingAbstractService<T>;
  @Input() filterConfig?: FilterConfig<U, V>;

  @ContentChild('pagingTemplate') pagingTemplate!: TemplateRef<{
    paging: Paging<T>;
  }>;

  showMoreCallback$!: PaginationInfiniteComponent<T>['showMoreCallback$'];
  pagingPageQueryParameterName = 'page';
  queryParamKey = 'q';

  private searchString?: string;
  private filters?: Record<string, string>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.createCallback();
  }

  filtered(filters: V) {
    this.filters = mapValues(
      pickBy(filters, (value) => value !== undefined),
      (value) => `${value}`,
    );
    this.createCallback();
  }

  private createCallback() {
    this.showMoreCallback$ = (params) => {
      return this.pagingService.getPagingTruthy$({
        queryParamsExtra: {
          [this.pagingPageQueryParameterName]: params.pageNumber.toString(),
          ...(this.searchString
            ? { [this.queryParamKey]: this.searchString }
            : {}),
          ...(this.filters || {}),
        },
      });
    };
    this.changeDetectorRef.detectChanges();
  }

  searched(searchString: string) {
    this.searchString = searchString;
    this.createCallback();
  }
}
