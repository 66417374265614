<tr *ngFor="let pricePurchaseRecord of pricePurchaseRecords">
  <td>
    <ts-guid-ui [guid]="pricePurchaseRecord.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="pricePurchaseRecord"
    ></ts-date-created-updated-ui>
    <!-- TODO(Farid): Make better ui for is active -->
    <div>Sedang Aktif: {{ pricePurchaseRecord.is_active }}</div>
  </td>
  <td>
    {{ pricePurchaseRecord.product.title }}
  </td>
  <td>
    <ts-supplier-mention-ui
      [supplier]="pricePurchaseRecord.supplier"
    ></ts-supplier-mention-ui>
  </td>
  <td>
    <ts-money-ui [money]="pricePurchaseRecord.price"></ts-money-ui>
  </td>
  <td>
    <ts-money-ui [money]="pricePurchaseRecord.price_raw"></ts-money-ui>
  </td>
  <td>
    <ts-money-ui [money]="pricePurchaseRecord.discount"></ts-money-ui>
  </td>
  <td>
    <ts-fraction-as-percentage-ui
      [fraction]="pricePurchaseRecord.tax_fraction"
    ></ts-fraction-as-percentage-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: pricePurchaseRecord }"
    >
    </ng-template>
  </td>
</tr>
