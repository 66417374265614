import { sum } from 'lodash';
import { map, Observable, of, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { Product } from '@ts/product/shared/util-core';
import { AuthenticationService } from '@ts/shared/authentication/data-access';

import { BasketLinesStreamService } from './basket-lines-stream.service';

/**
 * Helper utilities for reading information from user's basket.
 */
@Injectable({
  providedIn: 'root',
})
export class BasketReadonlyService {
  constructor(
    private authenticationService: AuthenticationService,
    private basketLinesStreamService: BasketLinesStreamService,
  ) {}

  getProductQuantityInBasket$(product: Product): Observable<number> {
    return this.authenticationService.authenticationState$.pipe(
      switchMap((state) => {
        if (!state) {
          // user has not logged in, so basket is empty
          return of(0);
        } else {
          // return sum of all basket lines with matching product
          return this.basketLinesStreamService.truthyEntity$.pipe(
            map((lines) => {
              return sum(
                lines
                  .filter((line) => line.product.id === product.id)
                  .map((line) => line.quantity),
              );
            }),
          );
        }
      }),
    );
  }
}
