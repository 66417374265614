<tr *ngFor="let product of products">
  <td>
    <div>{{ product.title }}</div>
    <div>{{ product.slug }}</div>
  </td>
  <td>{{ product.upc }}</td>
  <td>
    <ts-product-thumbnail-ui [product]="product"></ts-product-thumbnail-ui>
  </td>
  <td>
    {{ product.inventory_online.quantity_available }}
  </td>
  <td>
    <ts-product-price-ui
      [product]="product"
      displayType="inline"
    ></ts-product-price-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: product }"
    >
    </ng-template>
  </td>
</tr>
