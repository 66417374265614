import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { SharedGuidUiCoreModule } from '@ts/shared/guid/ui-core';
import { SupplierUiMentionModule } from '@ts/supplier/ui-mention';

import { PurchasingGroupMentionUiComponent } from './purchasing-group-mention-ui/purchasing-group-mention-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedGuidUiCoreModule,
    SupplierUiMentionModule,
  ],
  declarations: [PurchasingGroupMentionUiComponent],
  exports: [PurchasingGroupMentionUiComponent],
})
export class PurchasingGroupUiMentionModule {}
