import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { Observable } from 'rxjs';

import { FacilityListService } from '@ts/facility/list/data-access';
import { Facility } from '@ts/facility/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-facility-table',
  templateUrl: './facility-table.component.html',
  styleUrls: ['./facility-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityTableComponent implements OnInit {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<Facility>>;

  facilities$!: Observable<readonly Facility[] | null>;

  constructor(private facilityListService: FacilityListService) {}

  ngOnInit() {
    this.facilities$ = this.facilityListService.getFacilities$();
  }
}
