import { Injectable } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable()
export class PromoUsagePresetProvider {
  getConfiguration(): FormlyFieldConfig {
    return {
      wrappers: ['card'],
      props: { label: 'Promo Usage' },
      type: undefined,
      fieldGroup: [
        {
          key: 'uses_total_max',
          type: 'input',
          props: {
            label: 'Jumlah pemakaian total maksimal',
            type: 'number',
            description: 'Kosongkan jika tidak ada batas pemakaian',
            required: false,
          },
        },
        {
          key: 'uses_per_user_max',
          type: 'input',
          props: {
            label: 'Jumlah pemakaian per user maksimal',
            type: 'number',
            description: 'Kosongkan jika tidak ada batas pemakaian',
            required: false,
          },
        },
      ],
    };
  }
}
