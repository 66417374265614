<ts-pagination-infinite-table [pagingService]="promoStaffPagingService">
  <ts-promo-staff-table-header-ui
    slot="header"
  ></ts-promo-staff-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-promo-staff-table-body-ui
      [promoStaffs]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-promo-staff-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
