import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { PricePurchaseRecord } from '@ts/price/purchase/shared/util-core';

@Component({
  selector: 'ts-price-purchase-record-select-modal',
  templateUrl: './price-purchase-record-select-modal.component.html',
  styleUrls: ['./price-purchase-record-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePurchaseRecordSelectModalComponent {
  @Input() initial?: PricePurchaseRecord;

  constructor(private modalController: ModalController) {}

  selected(pricePurchaseRecord: PricePurchaseRecord | null) {
    this.modalController.dismiss({ pricePurchaseRecord });
  }
}
