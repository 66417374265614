import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';

import { strict as assert } from 'assert';
import { filter, take, tap } from 'rxjs';

import { Store } from '@ngrx/store';
import { Category } from '@ts/category/shared/util-core';
import {
  categoryTreeSelect,
  selectCategoryTreeSelectState,
} from '@ts/category/tree/data-access';
import { LinkExpanderUiComponent } from '@ts/shared/link/ui-expander';

@Component({
  selector: 'ts-category-tree-node',
  templateUrl: './category-tree-node.component.html',
  styleUrls: ['./category-tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTreeNodeComponent implements AfterViewInit {
  @Input() category!: Category;

  @ViewChild(LinkExpanderUiComponent) linkExpanderUi!: LinkExpanderUiComponent;

  constructor(private store: Store) {}

  ngAfterViewInit() {
    this.store
      .select(selectCategoryTreeSelectState)
      .pipe(
        take(1),
        filter((state) => !!state?.ancestorsInitial),
        tap((state) => {
          assert(state);
          assert(state.ancestorsInitial);
          if (
            !state.noOpenChildCategoriesInitial &&
            state.ancestorsInitial.find(
              (ancestor) => ancestor.id === this.category.id,
            )
          ) {
            this.linkExpanderUi.clickShowContent();
          }
        }),
      )
      .subscribe();
  }

  selected() {
    this.store.dispatch(categoryTreeSelect({ category: this.category }));
  }
}
