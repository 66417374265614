import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { Basket } from '@ts/basket/shared/util-core';
import { BormaDagoPersistentAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { ApiEntityStreamPersistentAbstractService } from '@ts/shared/data/data-access-api-entity-stream';
import { UserStreamService } from '@ts/user/shared/data-access';

export type BasketIdOnly = Pick<Basket, 'id'>;

/**
 * Retrieves the logged-in user's basket id.
 *
 * Persistent.
 */
@Injectable({
  providedIn: 'root',
})
export class BasketIdStreamService extends ApiEntityStreamPersistentAbstractService<BasketIdOnly> {
  getBasketIdUrl = 'api/basket/user/%d/basket_id/';

  constructor(
    protected override apiService: BormaDagoPersistentAuthenticatedApiConsumerService,
    private userStreamService: UserStreamService,
  ) {
    super();
  }

  override get relativeUrl$(): Observable<string> {
    return this.userStreamService.truthyEntity$.pipe(
      map((user) => sprintf(this.getBasketIdUrl, user.id)),
    );
  }
}
