<table>
  <thead>
    <ts-user-table-staff-header-ui></ts-user-table-staff-header-ui>
  </thead>
  <tbody>
    <ts-user-table-staff-body-ui
      [users]="users"
      [actionTemplate]="actionTemplate"
    ></ts-user-table-staff-body-ui>
  </tbody>
</table>
