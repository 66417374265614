import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';
import { TransportLaneSelectModalComponent } from '@ts/transport/lane/action/feature-select';
import { TransportLane } from '@ts/transport/shared/util-core';

@Component({
  selector: 'ts-transport-lane-form-field',
  templateUrl: './transport-lane-form-field.component.html',
  styleUrls: ['./transport-lane-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportLaneFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'transportLane';
  initial?: TransportLane;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<TransportLane>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: TransportLaneSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
