import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  ViewChild,
} from '@angular/core';

import { Observable, take } from 'rxjs';

import { PaginationUtilService, Paging } from '@ts/shared/pagination/util-core';
import { SpinnerUiComponent } from '@ts/shared/ui-await-spinner';

import { PaginationInfiniteChildComponent } from '../pagination-infinite-child/pagination-infinite-child.component';
import { PaginationInfiniteTemplateContainerDirective } from '../pagination-infinite-template-container/pagination-infinite-template-container.directive';
import { PaginationInfiniteTemplateDirective } from '../pagination-infinite-template/pagination-infinite-template.directive';

@Component({
  selector: 'ts-pagination-infinite',
  templateUrl: './pagination-infinite.component.html',
  styleUrls: ['./pagination-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationInfiniteComponent<T> implements AfterContentInit {
  @Input() showMoreCallback$!: (params: {
    pageNumber: number;
  }) => Observable<Paging<T>>;

  @ViewChild(PaginationInfiniteTemplateContainerDirective, { static: true })
  paginationInfiniteTemplateContainerDirective!: PaginationInfiniteTemplateContainerDirective;

  @ContentChild(PaginationInfiniteTemplateDirective)
  paginationInfiniteTemplateDirective!: PaginationInfiniteTemplateDirective<T>;

  isButtonHidden = false;
  pageNumber = 1;
  isNoResult = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private paginationUtilService: PaginationUtilService,
  ) {}

  ngAfterContentInit() {
    this.showMore();
  }

  private createNextCallbackComponent(
    callbackResult: Paging<T>,
    finalPage: number,
  ) {
    const viewContainerRef =
      this.paginationInfiniteTemplateContainerDirective.viewContainerRef;
    const component = viewContainerRef.createComponent<
      PaginationInfiniteChildComponent<T>
    >(PaginationInfiniteChildComponent).instance;
    component.paging = callbackResult;
    component.template = this.paginationInfiniteTemplateDirective.template;
    if (callbackResult.meta.page >= finalPage) this.isButtonHidden = true;
    this.changeDetectorRef.detectChanges();
  }

  showMore() {
    const viewContainerRef =
      this.paginationInfiniteTemplateContainerDirective.viewContainerRef;
    const spinner =
      viewContainerRef.createComponent<SpinnerUiComponent>(SpinnerUiComponent);
    this.isButtonHidden = true;

    const isFirstPage = this.pageNumber === 1;
    this.showMoreCallback$({
      pageNumber: this.pageNumber,
    })
      .pipe(take(1))
      .subscribe((result) => {
        spinner.destroy();
        const finalPage = this.paginationUtilService.computeFinalPagingPage(
          result.meta,
        );
        if (result.meta.page < finalPage) {
          this.isButtonHidden = false;
        } else if (isFirstPage && !result.entities.length) {
          this.isNoResult = true;
        }
        this.createNextCallbackComponent(result, finalPage);
      });

    this.pageNumber += 1;
  }
}
