import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import {
  HasDateCreatedTimestamp,
  HasDateEditedTimestamp,
  Timestamp,
} from '@ts/shared/date-time/util-core';

@Component({
  selector: 'ts-date-created-updated-ui',
  templateUrl: './date-created-updated-ui.component.html',
  styleUrls: ['./date-created-updated-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateCreatedUpdatedUiComponent implements OnInit {
  @Input() instance!: HasDateCreatedTimestamp | HasDateEditedTimestamp;
  date_created_timestamp?: Timestamp;
  date_updated_timestamp?: Timestamp;

  ngOnInit() {
    if ('date_created_timestamp' in this.instance) {
      this.date_created_timestamp = this.instance.date_created_timestamp;
    }
    if ('date_edited_timestamp' in this.instance) {
      this.date_updated_timestamp = this.instance.date_edited_timestamp;
    }
  }
}
