import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { LogoConfig } from '@ts/shared/logo/ui-core';

export const BORMA_DAGO_LOGO_ALT = 'Bormadago logo';
export type LogoShopType = 'logo-only' | 'logo-full';

@Component({
  selector: 'ts-logo-shop-ui',
  templateUrl: './logo-shop-ui.component.html',
  styleUrls: ['./logo-shop-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoShopUiComponent implements OnInit {
  @Input() logoShopType!: LogoShopType;

  logoConfig!: LogoConfig;

  ngOnInit() {
    let logoConfig: LogoConfig;
    switch (this.logoShopType) {
      case 'logo-full':
        logoConfig = {
          name: 'bormadago_full',
          alt: BORMA_DAGO_LOGO_ALT,
        };
        break;
      case 'logo-only':
        logoConfig = {
          name: 'bormadago',
          alt: BORMA_DAGO_LOGO_ALT,
        };
        break;
    }
    this.logoConfig = logoConfig;
  }
}
