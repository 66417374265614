<table>
  <thead>
    <ts-receiving-table-header-ui></ts-receiving-table-header-ui>
  </thead>
  <tbody>
    <ts-receiving-table-body-ui
      [receivings]="receivings"
      [actionTemplate]="actionTemplate"
    ></ts-receiving-table-body-ui>
  </tbody>
</table>
