<ng-container *transloco="let t; scope: 'common'">
  <ts-link-ui *ngIf="initial" (click)="removeSelection()">
    {{ t('common.removeSelection') }}
  </ts-link-ui>

  <ng-template let-value="value" #actionTemplate>
    <ion-button (click)="selected.emit(value)" [disabled]="isInitial(value)">
      {{ t(isInitial(value) ? 'common.selected' : 'common.select') }}
    </ion-button>
  </ng-template>

  <ng-container
    [ngTemplateOutlet]="choicesTemplate"
    [ngTemplateOutletContext]="{ actionTemplate: actionTemplate }"
  ></ng-container>
</ng-container>
