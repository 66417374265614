import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PricePurchaseChange } from '@ts/price/purchase/shared/util-core';

@Component({
  selector: 'ts-price-purchase-change-mention-ui',
  templateUrl: './price-purchase-change-mention-ui.component.html',
  styleUrls: ['./price-purchase-change-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePurchaseChangeMentionUiComponent {
  @Input() pricePurchaseChange!: PricePurchaseChange;
}
