<ng-container *transloco="let t; scope: 'filter'">
  <ts-pagination-infinite-table
    [pagingService]="productStaffPagingService"
    [filterConfig]="{
      formFlexibleConfig: filterFormFlexibleConfig,
      formGenericConfig: {
        type: 'button',
        buttonText: t('filter.filter'),
        title: t('filter.productTitle'),
        ignoreScreenSize: true
      }
    }"
  >
    <ts-product-table-staff-header-ui
      slot="header"
    ></ts-product-table-staff-header-ui>

    <ng-template let-paging="paging" #pagingTemplate>
      <ts-product-table-staff-body-ui
        [products]="paging.entities"
        [actionTemplate]="actionTemplate"
      ></ts-product-table-staff-body-ui>
    </ng-template>
  </ts-pagination-infinite-table>
</ng-container>
