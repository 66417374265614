import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '../action-template.model';

@Component({
  selector: 'ts-choices-action-template-ui',
  templateUrl: './choices-action-template-ui.component.html',
  styleUrls: ['./choices-action-template-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChoicesActionTemplateUiComponent<T> {
  @Input() initial?: T;

  // null means the user has opted to remove the initial selection.
  @Output() selected = new EventEmitter<T | null>();

  @ContentChild('choicesTemplate') choicesTemplate!: TemplateRef<{
    actionTemplate: TemplateRef<ActionTemplateContext<T>>;
  }>;

  isInitial(value: T): boolean {
    return this.initial === value;
  }

  removeSelection() {
    this.selected.emit(null);
  }
}
