import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'ts-fraction-as-percentage-ui',
  templateUrl: './fraction-as-percentage-ui.component.html',
  styleUrls: ['./fraction-as-percentage-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FractionAsPercentageUiComponent implements OnInit {
  @Input() fraction!: number;

  formattedFraction!: string;

  ngOnInit() {
    this.formattedFraction = `${Math.round(this.fraction * 100)}%`;
  }
}
