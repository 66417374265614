import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Timestamp, TimestampService } from '@ts/shared/date-time/util-core';
import { syncStringPromise } from '@ts/shared/util-async';

@Component({
  selector: 'ts-modal-ui',
  templateUrl: './modal-ui.component.html',
  styleUrls: ['./modal-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalUiComponent implements OnInit, OnDestroy {
  @Input() title!: string | Promise<string>;

  /**
   * We need to assign a unique modal id to identify which modal to close with the back button.
   */
  modalId!: Timestamp;

  title$!: Promise<string>;

  constructor(
    private modalController: ModalController,
    private timestampService: TimestampService,
  ) {}

  @HostListener('window:popstate', ['$event'])
  popstate(event: PopStateEvent) {
    // Handles the case where the user clicks the back button.
    if (event.state.modalId === this.modalId && event.state.shouldCloseModal) {
      this.modalController.dismiss();
      // We need to re-pop this state too. Blame dumb javascript for this behavior.
      history.back();
      event.stopImmediatePropagation();
    }
  }

  ngOnInit() {
    this.title$ = syncStringPromise(this.title);

    // TODO(irvan): maybe find a better way to get modal id or to remove the use of a modal id altogether.
    this.modalId = this.timestampService.now();

    const modalState = {
      modal: true,
      modalId: this.modalId,
    };
    history.pushState(
      {
        ...modalState,
        shouldCloseModal: true,
      },
      'unused',
    );
    // For some dumb reason, window:popstate returns the new state instead of the popped state.
    // Thus, we have to push the state twice in order to read it.
    history.pushState(
      {
        ...modalState,
        shouldCloseModal: false,
      },
      'unused',
    );
  }

  ngOnDestroy() {
    // We need to handle two cases.
    if (window.history.state.modalId === this.modalId) {
      if (window.history.state.shouldCloseModal) {
        // This case should not be reachable. However, this code is extremely fragile, so we
        // put this just in case.
        history.back();
      } else {
        // This case is reached when the user navigates out of the page without closing the modal, e.g., clicking a link.
        history.go(-2);
      }
    }
  }

  closeModal() {
    // Handles the case where the user closes the modal manually.
    // We simulate clicking the back button to avoid duplicating logic.
    history.back();
  }
}
