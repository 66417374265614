import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { Liability } from '@ts/finance/liability/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-finance-liability-table-ui',
  templateUrl: './finance-liability-table-ui.component.html',
  styleUrls: ['./finance-liability-table-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceLiabilityTableUiComponent {
  @Input() liabilities!: readonly Liability[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @ContentChild('actionTemplate') actionTemplate?: TemplateRef<
    ActionTemplateContext<Liability>
  >;
}
