import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { delay, of, tap } from 'rxjs';

@Component({
  selector: 'ts-searchbar-ui',
  templateUrl: './searchbar-ui.component.html',
  styleUrls: ['./searchbar-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchbarUiComponent implements OnChanges {
  /**
   * If given, this will be shown under the text as the list of input suggestions.
   */
  @Input() autocompleteSuggestions: readonly string[] = [];

  /**
   * Triggered when user has submitted the form.
   */
  @Output() searched = new EventEmitter<string>();

  /**
   * Triggered when the user has manually typed a text into the form with `debouceTimeMs` debounce.
   * Further debouncing/throttling may be required.
   * Useful for supplying new auto-complete texts.
   */
  @Output() queryTyped = new EventEmitter<string>();

  debounceTimeMs = 100;

  searchForm = this.formBuilder.group({
    searchString: '',
  });

  /**
   * Whether the input is currently being focused on.
   */
  isFocused = false;

  // =============================
  // Autocomplete variables
  // =============================

  /**
   * Index of the autocomplete cursor. zero means that it's still focused
   * on the current input. Otherwise, it's pointing to index (autocompleteIndex - 1)
   */
  private autocompleteIndex = 0;

  /**
   * When the user navigates autocomplete with keyboard, autocomplete will override
   * the displayed search string with the autocompleted version.
   * So, we need to save the original text in case the user moves the cursor back.
   * That's the content of this variable.
   */
  private autocompleteOriginalSearchString?: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['autocompleteSuggestions']) {
      // clear the autocomplete data whenever the suggestions change
      this.autocompleteIndex = 0;
      this.autocompleteOriginalSearchString = undefined;
    }
  }

  search() {
    this.querySearched();
  }

  inputChanged() {
    const searchString = this.getFormSearchString();
    // only emit asking for new suggestion if:
    // 1) input is currently being focused on
    // 2) it's not the currently selected one
    // 3) it's not the original one, and
    if (
      this.isFocused &&
      searchString !== this.getAutocompleteSuggestionAtIndex() &&
      searchString !== this.autocompleteOriginalSearchString
    ) {
      this.queryTyped.emit(this.getFormSearchString());
    }
  }

  autocompleteSuggestionClicked(autocompleteSuggestion: string) {
    // no need to debounce since we're not searching via form
    this.searched.emit(autocompleteSuggestion);
    this.searchForm.setValue({
      searchString: autocompleteSuggestion,
    });
    this.autocompleteIndex = 0;
  }

  keyPressed(event: KeyboardEvent) {
    // only relevant if we have autocomplete
    if (!this.autocompleteSuggestions.length) {
      return;
    }

    const autocompleteIndexOriginal = this.autocompleteIndex;

    switch (event.code) {
      case 'ArrowUp':
        this.autocompleteIndex = this.autocompleteIndex - 1;
        break;
      case 'ArrowDown':
        this.autocompleteIndex = this.autocompleteIndex + 1;
        break;
    }

    if (this.autocompleteIndex !== autocompleteIndexOriginal) {
      const indexLimit = this.autocompleteSuggestions.length + 1;
      this.autocompleteIndex =
        (this.autocompleteIndex + indexLimit) % indexLimit;

      // update the form value
      if (this.autocompleteIndex === 0) {
        // restore the saved search string
        this.searchForm.setValue({
          searchString: this.autocompleteOriginalSearchString,
        });
      } else {
        if (autocompleteIndexOriginal === 0) {
          // save the search string before replacing it
          this.autocompleteOriginalSearchString = this.getFormSearchString();
        }
        this.searchForm.setValue({
          searchString: this.getAutocompleteSuggestionAtIndex(),
        });
      }

      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Return the string of the autocomplete option currently selected by the cursor, if any
   */
  getAutocompleteSuggestionAtIndex(): string | undefined {
    if (this.autocompleteIndex === 0) {
      return undefined;
    } else {
      return this.autocompleteSuggestions[this.autocompleteIndex - 1];
    }
  }

  getAutocompleteSuggestionItemClasses(
    autocompleteSuggestion: string,
  ): readonly string[] {
    const classes: string[] = [];
    if (autocompleteSuggestion === this.getAutocompleteSuggestionAtIndex()) {
      classes.push('autocomplete-suggestion--at-index');
    }

    return classes;
  }

  focused() {
    this.isFocused = true;
    this.changeDetectorRef.detectChanges();
  }

  unfocused() {
    this.isFocused = false;
    this.changeDetectorRef.detectChanges();
    // also reset the autocomplete cursor
    this.autocompleteIndex = 0;
  }

  private querySearched() {
    this.searchForm.disable();

    // wait until the debounce time is over
    of(true)
      .pipe(
        delay(this.debounceTimeMs),
        tap(() => {
          this.searchForm.enable();
          this.searched.emit(this.getFormSearchString());
        }),
      )
      .subscribe();
  }

  private getFormSearchString(): string {
    return this.searchForm.value.searchString;
  }
}
