import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SupplierMentionUiComponent } from './supplier-mention-ui/supplier-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [SupplierMentionUiComponent],
  exports: [SupplierMentionUiComponent],
})
export class SupplierUiMentionModule {}
