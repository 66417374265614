import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { PricePurchaseChange } from '@ts/price/purchase/shared/util-core';

@Component({
  selector: 'ts-price-purchase-change-select-modal',
  templateUrl: './price-purchase-change-select-modal.component.html',
  styleUrls: ['./price-purchase-change-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePurchaseChangeSelectModalComponent {
  @Input() initial?: PricePurchaseChange;

  constructor(private modalController: ModalController) {}

  pricePurchaseChangeSelected(pricePurchaseChange: PricePurchaseChange | null) {
    this.modalController.dismiss({ pricePurchaseChange });
  }
}
