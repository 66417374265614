import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocationUtilService {
  constructor(
    private locationStrategy: LocationStrategy,
    private router: Router,
  ) {}

  getExternalUrl(...params: Parameters<Router['createUrlTree']>): string {
    return this.locationStrategy.prepareExternalUrl(
      this.router.serializeUrl(this.router.createUrlTree(...params)),
    );
  }
}
