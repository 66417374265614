import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { PaginationInfiniteTemplateDirectiveContext } from '@ts/shared/pagination/infinite/util-core';
import { Paging } from '@ts/shared/pagination/util-core';

@Component({
  selector: 'ts-pagination-infinite-child',
  templateUrl: './pagination-infinite-child.component.html',
  styleUrls: ['./pagination-infinite-child.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationInfiniteChildComponent<T> {
  @Input() paging!: Paging<T>;
  @Input() template!: TemplateRef<
    PaginationInfiniteTemplateDirectiveContext<T>
  >;
}
