import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { Promo } from '@ts/promo/shared/util-core';
import { I18nService } from '@ts/shared/18n/util-core';

@Component({
  selector: 'ts-promo-name-ui',
  templateUrl: './promo-name-ui.component.html',
  styleUrls: ['./promo-name-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoNameUiComponent implements OnInit {
  @Input() promo!: Promo;

  name!: string;

  constructor(private i18nService: I18nService) {}

  ngOnInit() {
    const language = this.i18nService.getLanguageActive();
    this.name = this.promo[`name_${language}`];
  }
}
