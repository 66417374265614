import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { PRODUCT_UPDATE_STAFF_UPC_AUTOMATIC } from '@ts/product/shared/util-core';

@Component({
  selector: 'ts-product-upc-form-field-ui',
  templateUrl: './product-upc-form-field-ui.component.html',
  styleUrls: ['./product-upc-form-field-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductUpcFormFieldUiComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  hasInitial!: boolean;

  ngOnInit() {
    this.hasInitial = !!this.formControl.value;
  }

  automaticClicked() {
    this.formControl.setValue(PRODUCT_UPDATE_STAFF_UPC_AUTOMATIC);
  }
}
