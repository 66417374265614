import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FacilityZone } from '@ts/facility/shared/util-core';

@Component({
  selector: 'ts-facility-zone-mention-ui',
  templateUrl: './facility-zone-mention-ui.component.html',
  styleUrls: ['./facility-zone-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityZoneMentionUiComponent {
  @Input() facilityZone!: FacilityZone;
}
