import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import {
  ApiEntityPagingAbstractService,
  EntityPagingService,
} from '@ts/shared/pagination/data-access-entity';
import { TransportLane } from '@ts/transport/shared/util-core';

@Injectable({
  providedIn: 'root',
})
export class TransportLanePagingService extends ApiEntityPagingAbstractService<TransportLane> {
  relativeUrl$ = of('api/transport/staff/lane-search/');

  constructor(
    protected override apiService: BormaDagoAuthenticatedApiConsumerService,
    protected override entityPagingService: EntityPagingService,
  ) {
    super();
  }
}
