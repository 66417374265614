<ts-pagination-infinite-table [pagingService]="facilityZonePagingService">
  <ts-facility-zone-table-header-ui
    slot="header"
  ></ts-facility-zone-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-facility-zone-table-body-ui
      [facilityZones]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-facility-zone-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
