import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { PurchasingGroupPagingService } from '@ts/purchasing/group/list/data-access';
import { PurchasingGroup } from '@ts/purchasing/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-purchasing-group-list-infinite',
  templateUrl: './purchasing-group-list-infinite.component.html',
  styleUrls: ['./purchasing-group-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingGroupListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<PurchasingGroup>>;

  constructor(
    public purchasingGroupPagingService: PurchasingGroupPagingService,
  ) {}
}
