import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';

import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { CategorySearchStaffService } from '@ts/category/list/data-access';
import { Category } from '@ts/category/shared/util-core';
import { CategoriesTopStreamService } from '@ts/category/top/data-access';
import { noOpenChildCategoriesInitialSet } from '@ts/category/tree/data-access';

@Component({
  selector: 'ts-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTreeComponent implements OnInit {
  categories$!: Observable<readonly Category[] | null>;
  categoriesTop$!: Observable<readonly Category[] | null>;

  constructor(
    private categoriesTopStreamService: CategoriesTopStreamService,
    private categorySearchStaffService: CategorySearchStaffService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.categoriesTop$ = this.categoriesTopStreamService.entity$;
    // initially, show the top categories
    this.categories$ = this.categoriesTop$;
  }

  searched(searchString: string) {
    if (searchString) {
      this.store.dispatch(
        noOpenChildCategoriesInitialSet({ noOpenChildCategoriesInitial: true }),
      );
      this.categories$ = this.categorySearchStaffService.search$(searchString);
    } else {
      this.store.dispatch(
        noOpenChildCategoriesInitialSet({
          noOpenChildCategoriesInitial: false,
        }),
      );
      this.categories$ = this.categoriesTop$;
    }

    this.changeDetectorRef.detectChanges();
  }
}
