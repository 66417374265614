import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FractionAsPercentageUiComponent } from './fraction-as-percentage-ui/fraction-as-percentage-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [FractionAsPercentageUiComponent],
  exports: [FractionAsPercentageUiComponent],
})
export class SharedUiFractionAsPercentageModule {}
