import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Product, ProductRange } from '@ts/product/shared/util-core';

@Component({
  selector: 'ts-product-range-select-ui',
  templateUrl: './product-range-select-ui.component.html',
  styleUrls: ['./product-range-select-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRangeSelectUiComponent {
  @Input() productRange!: ProductRange;
  @Output() productAdded = new EventEmitter<Product>();
  @Output() productRemoved = new EventEmitter<Product>();

  isDisabled(product: Product): boolean {
    return !!this.productRange.products.find(
      (productInRange) => productInRange.id === product.id,
    );
  }
}
