import { Injectable } from '@angular/core';

import { EMPTY, Observable, of, switchMap, take } from 'rxjs';

import { Store } from '@ngrx/store';
import { CheckoutState } from '@ts/checkout/shared/util-core';
import { I18nService } from '@ts/shared/18n/util-core';
import { AlertService } from '@ts/shared/alert/util-core';

import { State } from './state/checkout.reducer';
import { selectCheckoutState } from './state/checkout.selector';

/**
 * For reading the local checkout state
 */
@Injectable({
  providedIn: 'root',
})
export class CheckoutReadonlyService {
  checkoutState$: Observable<State> = this.store.select(selectCheckoutState);

  /**
   * Returns ONE truthy checkout state, or returns nothing and alerts if checkout state is null.
   */
  checkoutStateTruthyOrEmpty$!: Observable<CheckoutState>;

  constructor(
    private store: Store,
    private alertService: AlertService,
    private i18nService: I18nService,
  ) {
    this.checkoutStateTruthyOrEmpty$ = this.checkoutState$.pipe(
      take(1),
      switchMap((checkoutState) => {
        if (!checkoutState) {
          this.alertService.error$({
            message: this.i18nService.translate$(
              'sharedApiDataAccessBormaDagoApiConsumer.error.checkoutStateMissing',
            ),
          });
          return EMPTY;
        } else {
          return of(checkoutState);
        }
      }),
    );
  }
}
