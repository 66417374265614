import { createAction, props } from '@ngrx/store';
import { Category } from '@ts/category/shared/util-core';

export const initCategoryTreeSelect = createAction(
  '[category.tree.data-access] Init Category Tree Select',
  props<{ ancestors?: readonly Category[] }>(),
);

export const noOpenChildCategoriesInitialSet = createAction(
  '[category.tree.data-access] Category Tree Set Disallow Opening Child Categories Initial',
  props<{ noOpenChildCategoriesInitial?: boolean }>(),
);

export const categoryTreeSelect = createAction(
  '[category.tree.data-access] Category Tree Select',
  props<{ category: Category }>(),
);
