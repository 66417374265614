import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { ButtonSelectFormFieldUiComponent } from './button-select-form-field-ui/button-select-form-field-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlySelectModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'button-select',
          component: ButtonSelectFormFieldUiComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [ButtonSelectFormFieldUiComponent],
  exports: [ButtonSelectFormFieldUiComponent],
})
export class SharedFormFieldUiButtonSelectModule {}
