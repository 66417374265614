import { Router } from '@angular/router';

export abstract class RouterUrlAbstractService {
  abstract baseUrl: string;

  constructor(protected router: Router) {}

  absoluteUrl(...params: Parameters<Router['createUrlTree']>): string {
    return `${this.baseUrl}${this.router.serializeUrl(
      this.router.createUrlTree(...params),
    )}`;
  }
}
