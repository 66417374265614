<ng-container *ngIf="categoryInitial$">
  <ng-container *tsAwaitSpinner="categoryInitial$ | async as categoryInitial">
    <ng-container
      [ngTemplateOutlet]="fieldUi"
      [ngTemplateOutletContext]="{ initial: categoryInitial }"
    ></ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="!categoryInitial$">
  <ng-container
    [ngTemplateOutlet]="fieldUi"
    [ngTemplateOutletContext]="{ initial: undefined }"
  ></ng-container>
</ng-container>

<ng-template #fieldUi let-initial="initial">
  <ts-form-field-select-with-modal-ui
    [initial]="initial"
    [modalShowParamsCallback]="modalShowParamsCallback"
    [modalOutputPropertyName]="modalOutputPropertyName"
    (entitySelected)="categorySelected($event)"
  >
    <ng-template let-entity="entity" #entityTemplate>
      [{{ entity.full_code }}] {{ entity.name }}
    </ng-template>
  </ts-form-field-select-with-modal-ui>
</ng-template>
