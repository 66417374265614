import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FacilityZoneMentionUiComponent } from './facility-zone-mention-ui/facility-zone-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [FacilityZoneMentionUiComponent],
  exports: [FacilityZoneMentionUiComponent],
})
export class FacilityZoneUiMentionModule {}
