import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Category } from '@ts/category/shared/util-core';

/**
 * Shows a modal asking user to select a category from the category tree.
 * If the user does so, dismiss will be called with a {category: 'selected category'} parameter.
 */
@Component({
  selector: 'ts-category-tree-select-modal',
  templateUrl: './category-tree-select-modal.component.html',
  styleUrls: ['./category-tree-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTreeSelectModalComponent {
  @Input() categoryInitial?: Category;

  constructor(private modalController: ModalController) {}

  categorySelected(category: Category) {
    this.modalController.dismiss({ category });
  }
}
