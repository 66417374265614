import { createReducer, on } from '@ngrx/store';
import { CheckoutState } from '@ts/checkout/shared/util-core';

import {
  clearPromoVoucher,
  initCheckout,
  resetCheckout,
  setCheckoutStep,
  setPaymentInfo,
  setPromoVoucher,
  setShippingInfo,
} from './checkout.action';

export type State = CheckoutState | null;

export const initialState: State = null;

export const checkoutReducer = createReducer<State>(
  initialState,
  on(initCheckout, (state, { basket }) => {
    return {
      body: {
        promo_vouchers: [],
      },
      meta: {
        basket_id: basket.id,
        basket_edit_timestamp: basket.date_edited_timestamp,
        basket_total: basket.total_price,
      },
    };
  }),
  on(resetCheckout, () => {
    return null;
  }),
  on(setShippingInfo, (state, { shippingInfo }) => {
    if (!state) {
      return state;
    } else {
      return {
        ...state,
        body: {
          ...state.body,
          shipping_info: shippingInfo,
        },
      };
    }
  }),
  on(setPaymentInfo, (state, { paymentInfo }) => {
    if (!state) {
      return state;
    } else {
      return {
        ...state,
        body: {
          ...state.body,
          payment_info: paymentInfo,
        },
      };
    }
  }),
  on(setCheckoutStep, (state, { checkoutStep }) => {
    if (!state) {
      return state;
    } else {
      return {
        ...state,
        meta: {
          ...state.meta,
          checkoutStep,
        },
      };
    }
  }),
  on(setPromoVoucher, (state, { promoVoucher }) => {
    if (!state) {
      return state;
    } else {
      return {
        ...state,
        body: {
          ...state.body,
          promo_vouchers: state.body.promo_vouchers
            .filter((promo) => promo.voucher_type !== promoVoucher.voucher_type)
            .concat(promoVoucher),
        },
      };
    }
  }),
  on(clearPromoVoucher, (state, { promoVoucherType }) => {
    if (!state) {
      return state;
    } else {
      return {
        ...state,
        body: {
          ...state.body,
          promo_vouchers: state.body.promo_vouchers.filter(
            (promo) => promo.voucher_type !== promoVoucherType,
          ),
        },
      };
    }
  }),
);
