import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { Category } from '@ts/category/shared/util-core';
import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';

@Injectable({
  providedIn: 'root',
})
export class CategoryTreeService {
  categoryChildrenUrl = 'api/category/%d/subcategories/';
  categoryAncestorsUrl = 'api/category/%d/ancestors/';

  constructor(private apiService: BormaDagoAuthenticatedApiConsumerService) {}

  getCategoryChildren$(
    category: Category,
  ): Observable<readonly Category[] | null> {
    return this.apiService.get$({
      relativeUrl: sprintf(this.categoryChildrenUrl, category.id),
    });
  }

  getCategoryAncestors$(
    category: Category,
  ): Observable<readonly Category[] | null> {
    return this.apiService.get$({
      relativeUrl: sprintf(this.categoryAncestorsUrl, category.id),
    });
  }
}
