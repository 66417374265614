import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { CashFlowLanePagingService } from '@ts/finance/cash-flow/lane/list/data-access';
import { CashFlowLane } from '@ts/finance/cash-flow/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-finance-cash-flow-lane-list-infinite',
  templateUrl: './finance-cash-flow-lane-list-infinite.component.html',
  styleUrls: ['./finance-cash-flow-lane-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceCashFlowLaneListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<CashFlowLane>>;

  constructor(public cashFlowLanePagingService: CashFlowLanePagingService) {}
}
