import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { PromoUiNumUsesRemainingModule } from '@ts/promo/ui-num-uses-remaining';
import { SharedMoneyUiModule } from '@ts/shared/money/ui';
import { SharedUiFractionAsPercentageModule } from '@ts/shared/ui-fraction-as-percentage';

import { PromoProductLineDiscountInfoUiComponent } from './promo-product-line-discount-info-ui/promo-product-line-discount-info-ui.component';
import { PromoProductLineDiscountQuantityMultipleUiComponent } from './promo-product-line-discount-quantity-multiple-ui/promo-product-line-discount-quantity-multiple-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslocoModule,
    SharedUiFractionAsPercentageModule,
    SharedMoneyUiModule,
    PromoUiNumUsesRemainingModule,
  ],
  declarations: [
    PromoProductLineDiscountInfoUiComponent,
    PromoProductLineDiscountQuantityMultipleUiComponent,
  ],
  exports: [
    PromoProductLineDiscountInfoUiComponent,
    PromoProductLineDiscountQuantityMultipleUiComponent,
  ],
})
export class PromoProductLineUiDiscountInfoModule {}
