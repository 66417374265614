import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { BasketLine } from '@ts/basket/shared/util-core';
import { BormaDagoPersistentAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { ApiEntityStreamPersistentAbstractService } from '@ts/shared/data/data-access-api-entity-stream';

import { BasketIdStreamService } from './basket-id-stream.service';

/**
 * Retrieves the logged-in user's current basket's lines.
 *
 * Persistent.
 */
@Injectable({
  providedIn: 'root',
})
export class BasketLinesStreamService extends ApiEntityStreamPersistentAbstractService<
  readonly BasketLine[]
> {
  relativeUrl = 'api/basket/meta/%d/lines/';

  constructor(
    protected override apiService: BormaDagoPersistentAuthenticatedApiConsumerService,
    private basketIdStreamService: BasketIdStreamService,
  ) {
    super();
  }

  override get relativeUrl$(): Observable<string> {
    return this.basketIdStreamService.truthyEntity$.pipe(
      map((basket) => sprintf(this.relativeUrl, basket.id)),
    );
  }
}
