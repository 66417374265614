export type ProductAttributeType = 'float' | 'text';

export const PRODUCT_ATTRIBUTE_TYPE_TO_FORM_FIELD: {
  [productAttributeType in ProductAttributeType]: string;
} = {
  float: 'number',
  text: 'input',
};

interface ProductAttributeBase {
  code: string;
  type: ProductAttributeType;
  required: boolean;
}
export type ProductAttribute = Readonly<ProductAttributeBase>;

export type ProductAttributePartial = Pick<ProductAttribute, 'code'>;

interface ProductAttributeValueBase {
  attribute: ProductAttributePartial;
  value: string;
}

export type ProductAttributeValue = Readonly<ProductAttributeValueBase>;
