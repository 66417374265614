import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';
import { TransportSelectModalComponent } from '@ts/transport/action/feature-select';
import { Transport } from '@ts/transport/shared/util-core';

@Component({
  selector: 'ts-transport-form-field',
  templateUrl: './transport-form-field.component.html',
  styleUrls: ['./transport-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'transport';
  initial?: Transport;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<Transport>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: TransportSelectModalComponent,
        componentProps: { initial: entity },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
