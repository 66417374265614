<ts-pagination-infinite-table [pagingService]="cashStoragePagingService">
  <ts-finance-cash-storage-table-header-ui
    slot="header"
  ></ts-finance-cash-storage-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-finance-cash-storage-table-body-ui
      [cashStorages]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-finance-cash-storage-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
