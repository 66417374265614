import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { Category } from '@ts/category/shared/util-core';
import { BormaDagoApiConsumerService } from '@ts/shared/api/data-access-borma-dago-api-consumer';
import { ApiEntityStreamPersistentAbstractService } from '@ts/shared/data/data-access-api-entity-stream';

/**
 * Retrieves all the top level categories.
 *
 * Persistent.
 */
@Injectable({
  providedIn: 'root',
})
export class CategoriesTopStreamService extends ApiEntityStreamPersistentAbstractService<
  readonly Category[]
> {
  override relativeUrl$ = of('api/category/browsable/top/');

  constructor(protected override apiService: BormaDagoApiConsumerService) {
    super();
  }
}
