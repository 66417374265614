import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { FormFlexibleModalUiComponent } from '@ts/shared/form/flexible/ui-modal';
import { FormFlexibleConfig } from '@ts/shared/form/flexible/util-core';
import { ModalService } from '@ts/shared/modal/util-core';

@Component({
  selector: 'ts-form-flexible-button-ui',
  templateUrl: './form-flexible-button-ui.component.html',
  styleUrls: ['./form-flexible-button-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFlexibleButtonUiComponent<T, U = T> {
  @Input() config!: FormFlexibleConfig<T, U>;
  @Input() initial?: T;
  @Input() modalTitle!: string;
  @Input() buttonText!: string;
  @Output() submitted = new EventEmitter<U>();

  constructor(private modalService: ModalService) {}

  private async processModal$(modalPromise: Promise<HTMLIonModalElement>) {
    const modal = await modalPromise;
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.submitted.emit(data.data);
    }
  }

  showForm$() {
    this.processModal$(
      this.modalService.show$({
        component: FormFlexibleModalUiComponent,
        componentProps: {
          initial: this.initial,
          config: this.config,
          title: this.modalTitle,
        },
        backdropDismiss: true,
      }),
    );
  }
}
