import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ts-text-editor-field-ui',
  templateUrl: './text-editor-field-ui.component.html',
  styleUrls: ['./text-editor-field-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorFieldUiComponent extends FieldType<FieldTypeConfig> {}
