import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';

import { ShippingMethodsSelectPresetProvider } from './shipping-methods-select.preset';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlyPresetModule,
    FormlyModule.forChild({
      presets: [
        {
          name: 'shippingMethodsSelect',
          config: new ShippingMethodsSelectPresetProvider(),
        },
      ],
    }),
  ],
})
export class ShippingUiMethodFormModule {}
