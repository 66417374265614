import { Injectable } from '@angular/core';

import { Category } from '@ts/category/shared/util-core';
import { BormaDagoApiConsumerService } from '@ts/shared/api/data-access-borma-dago-api-consumer';
import { ApiEntityIdAbstractService } from '@ts/shared/data/data-access-api-id';

@Injectable({
  providedIn: 'root',
})
export class CategoryIdService extends ApiEntityIdAbstractService<Category> {
  relativeUrl = 'api/category/%d/';

  constructor(protected apiService: BormaDagoApiConsumerService) {
    super();
  }
}
