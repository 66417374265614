<span #componentTop></span>
<ts-form-scaffold-ui
  [formWrapper]="formlyWrapper.formWrapper"
  (submitted)="submitted.emit($event)"
>
  <formly-form
    [model]="formlyWrapper.model"
    [fields]="formlyWrapperFields"
    [options]="formlyWrapper.options"
    [form]="formlyWrapper.formWrapper.formGroup"
  ></formly-form>
  <ts-form-submit-button-ui
    [isLoading]="formlyWrapper.formWrapper.isLoading"
    [isSubmittable]="formlyWrapper.formWrapper.isSubmittable()"
    [submitButtonConfig]="submitButtonConfig"
  ></ts-form-submit-button-ui>
</ts-form-scaffold-ui>
