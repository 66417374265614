import { Injectable } from '@angular/core';

import { IonContent } from '@ionic/angular';

/**
 * Enables scrolling top top/bottom/etc.
 *
 * We cannot use angular's native ViewportController because ionic overrides the scrollbar within `ion-content`.
 *
 * This service will be initialized and re-initialized by `ts-content`, which should replace `ion-content` in most cases.
 */
@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  // We keep this as a list because there may be multiple ion contents in a page.
  // For example, we may have a modal on top of a page.
  ionContents: readonly IonContent[] = [];

  ionContentAdd(ionContent: IonContent) {
    this.ionContents = this.ionContents.concat(ionContent);
  }

  ionContentDestroy(ionContent: IonContent) {
    this.ionContents = this.ionContents.filter(
      (ionContentArray) => ionContentArray !== ionContent,
    );
  }

  scrollToTop() {
    if (this.ionContents.length) {
      this.ionContents[this.ionContents.length - 1].scrollToTop();
    }
  }
}
