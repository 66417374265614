import { Injectable } from '@angular/core';

import { BehaviorSubject, map, Observable, of, Subject, switchMap } from 'rxjs';

import { BasketIdOnly } from '@ts/basket/shared/data-access';
import { CheckoutReadonlyService } from '@ts/checkout/shared/data-access';
import { EntityStreamAbstractService } from '@ts/shared/data/data-access-entity-stream';

/**
 * For staff pages, instead of returning the user's basket id, we want to return another user's basket id.
 * This works in two ways. First, pages can manually set the value. Alternatively, this can retrieve the id from the checkout state.
 *
 * This isn't provided in root like most other services. Instead, this will replace `BasketIdStreamService` on staff pages.
 */
@Injectable()
export class BasketIdFixedAsStreamService extends EntityStreamAbstractService<BasketIdOnly> {
  private basketIdFixed$: Subject<BasketIdOnly | null> =
    new BehaviorSubject<BasketIdOnly | null>(null);

  constructor(private checkoutReadonlyService: CheckoutReadonlyService) {
    super();
  }

  override getRefreshedEntity$(): Observable<BasketIdOnly | null> {
    return this.basketIdFixed$.pipe(
      switchMap((basketId) => {
        if (basketId) {
          return of(basketId);
        } else {
          return this.checkoutReadonlyService.checkoutState$.pipe(
            map((checkoutState) => {
              if (!checkoutState) {
                return null;
              } else {
                return {
                  id: checkoutState.meta.basket_id,
                };
              }
            }),
          );
        }
      }),
    );
  }

  basketIdFix(basketId: number) {
    this.basketIdFixed$.next({ id: basketId });
  }

  basketIdClear() {
    this.basketIdFixed$.next(null);
  }
}
