<ng-container *ngIf="entityCurrent">
  <ng-template
    [ngTemplateOutlet]="entityTemplate"
    [ngTemplateOutletContext]="{ entity: entityCurrent }"
  >
  </ng-template>
</ng-container>
<div class="not-chosen" [hidden]="entityCurrent">
  <ng-container *transloco="let t; scope: 'sharedForm'">
    {{ t('sharedForm.noneSelected') }}
  </ng-container>
</div>
<ion-button (click)="search()">
  <ion-icon slot="icon-only" name="search"></ion-icon>
</ion-button>
<ion-button (click)="clear()" color="danger" [disabled]="!entityCurrent">
  <ion-icon slot="icon-only" name="trash"></ion-icon>
</ion-button>
