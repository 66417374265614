import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';

import { ProductUpcFormFieldUiComponent } from './product-upc-form-field-ui/product-upc-form-field-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlyIonicModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'product-upc',
          component: ProductUpcFormFieldUiComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [ProductUpcFormFieldUiComponent],
  exports: [ProductUpcFormFieldUiComponent],
})
export class ProductFormUiFieldModule {}
