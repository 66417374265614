<ts-pagination-infinite-table [pagingService]="receivingPagingService">
  <ts-receiving-table-header-ui slot="header"></ts-receiving-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-receiving-table-body-ui
      [receivings]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-receiving-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
