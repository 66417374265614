import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { TransportPagingService } from '@ts/transport/list/data-access';
import { Transport } from '@ts/transport/shared/util-core';

@Component({
  selector: 'ts-transport-list-infinite',
  templateUrl: './transport-list-infinite.component.html',
  styleUrls: ['./transport-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<Transport>>;

  constructor(public transportPagingService: TransportPagingService) {}
}
