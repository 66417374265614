import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { ProductFilterService } from '@ts/product/filter/data-access';
import {
  ProductFilterQueryParams,
  ProductFilterQueryParamsFormModel,
} from '@ts/product/filter/util-core';
import { ProductStaffPagingService } from '@ts/product/list/data-access';
import { ProductStaff } from '@ts/product/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { FormFlexibleConfig } from '@ts/shared/form/flexible/util-core';

@Component({
  selector: 'ts-product-staff-list-infinite',
  templateUrl: './product-staff-list-infinite.component.html',
  styleUrls: ['./product-staff-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductStaffListInfiniteComponent implements OnInit {
  @ContentChild('actionTemplate') actionTemplate!: TemplateRef<
    ActionTemplateContext<ProductStaff>
  >;

  filterFormFlexibleConfig!: FormFlexibleConfig<
    ProductFilterQueryParamsFormModel,
    ProductFilterQueryParams
  >;

  constructor(
    public productStaffPagingService: ProductStaffPagingService,
    private productFilterService: ProductFilterService,
  ) {}

  ngOnInit() {
    this.filterFormFlexibleConfig =
      this.productFilterService.getFormFlexibleConfig({ showCategory: true });
  }
}
