import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { User } from '@ts/user/shared/util-core';

@Component({
  selector: 'ts-user-mention-ui',
  templateUrl: './user-mention-ui.component.html',
  styleUrls: ['./user-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMentionUiComponent {
  @Input() user!: User;
}
