import { Directive } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// Needed because files are missing value accessor: https://github.com/angular/angular/issues/7341
// Angular people, do your work please!
@Directive({
  selector: '[tsFileValueAccessorUi]',

  // This is special, we will replace these functions down below, so we need to use host here.
  // Using HostListener or HostBinding does not seem to work.
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(change)': 'onChange($event.target.files)',
    '(blur)': 'onTouched()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileValueAccessorUiDirective,
      multi: true,
    },
  ],
})
export class FileValueAccessorUiDirective<T = unknown>
  implements ControlValueAccessor
{
  value?: T;

  onChange: (event: Event) => void = () => {};
  onTouched: (event: Event) => void = () => {};

  writeValue(/* _value: T */) {}
  registerOnChange(onChange: FileValueAccessorUiDirective<T>['onChange']) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: FileValueAccessorUiDirective<T>['onTouched']) {
    this.onTouched = onTouched;
  }
}
