import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { ReturnToSupplier } from '@ts/return-to-supplier/shared/util-core';

@Component({
  selector: 'ts-return-to-supplier-select-modal',
  templateUrl: './return-to-supplier-select-modal.component.html',
  styleUrls: ['./return-to-supplier-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnToSupplierSelectModalComponent {
  @Input() initial?: ReturnToSupplier;

  constructor(private modalController: ModalController) {}

  returnToSupplierSelected(returnToSupplier: ReturnToSupplier | null) {
    this.modalController.dismiss({ returnToSupplier });
  }
}
