<table>
  <thead>
    <ts-product-table-staff-header-ui></ts-product-table-staff-header-ui>
  </thead>
  <tbody>
    <ts-product-table-staff-body-ui
      [products]="products"
      [actionTemplate]="actionTemplate"
    >
    </ts-product-table-staff-body-ui>
  </tbody>
</table>
