<ts-form-field-select-with-modal-ui
  [modalShowParamsCallback]="modalShowParamsCallback"
  [modalOutputPropertyName]="modalOutputPropertyName"
  [formControl]="formControl"
  [initial]="productRangeInitials"
>
  <ng-template let-entity="entity" #entityTemplate>
    {{ entity.products.length }} produk terpilih
  </ng-template>
</ts-form-field-select-with-modal-ui>
