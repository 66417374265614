import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { QuillModule } from 'ngx-quill';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';

import { TextEditorFieldUiComponent } from './text-editor-field-ui/text-editor-field-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    QuillModule,
    FormlyIonicModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'text-editor',
          component: TextEditorFieldUiComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [TextEditorFieldUiComponent],
  exports: [TextEditorFieldUiComponent],
})
export class SharedFormUiTextEditorModule {}
