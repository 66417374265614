import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Liability } from '@ts/finance/liability/shared/util-core';

@Component({
  selector: 'ts-finance-liability-mention-ui',
  templateUrl: './finance-liability-mention-ui.component.html',
  styleUrls: ['./finance-liability-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceLiabilityMentionUiComponent {
  @Input() liability!: Liability;
}
