import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { Product } from '@ts/product/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-product-table-staff-body-ui',
  templateUrl: './product-table-staff-body-ui.component.html',
  styleUrls: ['./product-table-staff-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTableStaffBodyUiComponent {
  @Input() products!: readonly Product[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<Product>>;
}
