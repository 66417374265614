import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { ProductRangeFormFeatureFieldModule } from '@ts/product/range/form/feature-field';
import { SharedFormWrapperUiCardModule } from '@ts/shared/form/wrapper/ui-card';
import { SharedMoneyFormUiFieldModule } from '@ts/shared/money/form/ui-field';
import { ShippingUiMethodFormModule } from '@ts/shipping/ui-method-form';

import { PromoConditionPresetProvider } from './promo-condition.preset';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ShippingUiMethodFormModule,
    FormlyPresetModule,
    FormlyModule.forChild({
      presets: [
        {
          name: 'promoCondition',
          config: new PromoConditionPresetProvider(),
        },
      ],
    }),
    ProductRangeFormFeatureFieldModule,
    SharedMoneyFormUiFieldModule,
    SharedFormWrapperUiCardModule,
  ],
})
export class PromoConditionUiFormModule {}
