<tr *ngFor="let transport of transports">
  <td>
    <div>{{ transport.title }}</div>
    <ts-guid-ui [guid]="transport.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="transport"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <ts-transport-lane-mention-ui
      [transportLane]="transport.transport_lane"
    ></ts-transport-lane-mention-ui>
  </td>
  <td>
    <div>Tipe: {{ transport.type }}</div>
    <div>Status: {{ transport.status }}</div>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: transport }"
    ></ng-template>
  </td>
</tr>
