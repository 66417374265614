import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { RouterLinkDirective } from './router-link/router-link.directive';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [RouterLinkDirective],
  exports: [RouterLinkDirective],
})
export class SharedLinkFeatureCoreModule {}
