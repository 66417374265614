import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ts-guid-ui',
  templateUrl: './guid-ui.component.html',
  styleUrls: ['./guid-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuidUiComponent {
  @Input() guid!: string;
}
