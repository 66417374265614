<tr *ngFor="let facilityZone of facilityZones">
  <td>
    <div>{{ facilityZone.name }}</div>
    <ts-guid-ui [guid]="facilityZone.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="facilityZone"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <!-- TODO(ivan): make this display better in separate pr -->
    <div>Tipe: {{ facilityZone.type }}</div>
    <div>Tersedia Online: {{ facilityZone.is_available_online }}</div>
  </td>
  <td>
    {{ facilityZone.facility?.name }}
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: facilityZone }"
    >
    </ng-template>
  </td>
</tr>
