import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { categoryTreeSelectReducer } from './state/category-tree-select.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      'categoryTreeSelectState',
      categoryTreeSelectReducer,
    ),
  ],
})
export class CategoryTreeDataAccessModule {}
