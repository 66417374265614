import { Injectable } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  PromoBenefitAmountType,
  PromoBenefitType,
} from '@ts/promo/shared/util-core';

interface PromoBenefitTypeOption {
  text: string;
  value: PromoBenefitType;
}

interface PromoBenefitAmountTypeOption {
  text: string;
  value: PromoBenefitAmountType;
}

const PROMO_AMOUNT_TYPE_FIXED: PromoBenefitAmountType = 'fixed';
const PROMO_AMOUNT_TYPE_FRACTION: PromoBenefitAmountType = 'fraction';

@Injectable()
export class PromoBenefitPresetProvider {
  PROMO_BENEFIT_TYPES: {
    [promoBenefitType in PromoBenefitType]: string;
  } = {
    'basket-discount': 'Diskon Keranjang',
    'shipping-discount': 'Diskon Pengiriman',
    cashback: 'Cashback',
  };

  PROMO_AMOUNT_TYPES: {
    [promoAmountType in PromoBenefitAmountType]: string;
  } = {
    fixed: 'Fixed',
    fraction: 'Fraksi',
  };

  getConfiguration(): FormlyFieldConfig {
    return {
      wrappers: ['card'],
      props: { label: 'Promo Benefit' },
      type: undefined,
      fieldGroup: [
        {
          key: 'type',
          type: 'select',
          props: {
            label: 'Pilih jenis benefit',
            options: [...this.getPromoBenefitTypeOptions()],
            valueProp: 'value',
            labelProp: 'text',
            required: true,
          },
        },
        {
          key: 'amount_type',
          type: 'select',
          props: {
            label: 'Pilih jenis jumlah diskon / cashback',
            options: [...this.getPromoBenefitAmountTypeOptions()],
            valueProp: 'value',
            labelProp: 'text',
            required: true,
          },
        },
        {
          fieldGroup: [
            {
              key: 'amount_fixed',
              type: 'money',
              props: {
                required: true,
                label: 'Jumlah',
              },
            },
          ],
          expressions: {
            hide: ({ model }) => {
              return model?.amount_type !== PROMO_AMOUNT_TYPE_FIXED;
            },
          },
        },
        {
          fieldGroup: [
            {
              key: 'fraction',
              type: 'float',
              props: {
                required: true,
                label: 'Persentase diskon',
              },
            },
            {
              key: 'fraction_amount_max',
              type: 'money',
              props: {
                required: true,
                label: 'Batas maksimal diskon',
              },
            },
          ],
          expressions: {
            hide: ({ model }) => {
              return model?.amount_type !== PROMO_AMOUNT_TYPE_FRACTION;
            },
          },
        },
      ],
    };
  }

  private getPromoBenefitTypeOptions(): readonly PromoBenefitTypeOption[] {
    return Object.entries(this.PROMO_BENEFIT_TYPES).map(([key, value]) => ({
      text: value,
      value: key as PromoBenefitType,
    }));
  }

  private getPromoBenefitAmountTypeOptions(): readonly PromoBenefitAmountTypeOption[] {
    return Object.entries(this.PROMO_AMOUNT_TYPES).map(([key, value]) => ({
      text: value,
      value: key as PromoBenefitAmountType,
    }));
  }
}
