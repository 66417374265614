import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { PromoStaff } from '@ts/promo/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-promo-staff-table-ui',
  templateUrl: './promo-staff-table-ui.component.html',
  styleUrls: ['./promo-staff-table-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoStaffTableUiComponent {
  @Input() promoStaffs!: readonly PromoStaff[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @ContentChild('actionTemplate') actionTemplate?: TemplateRef<
    ActionTemplateContext<PromoStaff>
  >;
}
