import { Injectable } from '@angular/core';

import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { ApiEntityIdAbstractService } from '@ts/shared/data/data-access-api-id';
import { User } from '@ts/user/shared/util-core';

@Injectable({
  providedIn: 'root',
})
export class UserIdService extends ApiEntityIdAbstractService<User> {
  relativeUrl = 'api/user/staff/%d/';

  constructor(protected apiService: BormaDagoAuthenticatedApiConsumerService) {
    super();
  }
}
