import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { SubmitButtonConfig } from '@ts/shared/form/ui-submit-button';
import { FormlyWrapper } from '@ts/shared/form/util-core';

@Component({
  selector: 'ts-formly-ui',
  templateUrl: './formly-ui.component.html',
  styleUrls: ['./formly-ui.component.scss'],
  // can't use OnPush for form
})
export class FormlyUiComponent<T> implements OnInit, OnDestroy {
  @Input() formlyWrapper!: FormlyWrapper<T>;
  @Input() submitButtonConfig: SubmitButtonConfig = {};

  @Output() submitted = new EventEmitter<T>();

  @ViewChild('componentTop') componentTop!: ElementRef;

  subscription?: Subscription;
  formlyWrapperFields!: FormlyFieldConfig[];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.formlyWrapperFields = [...this.formlyWrapper.fields];

    this.subscription = this.formlyWrapper.formWrapper.stateChanges$.subscribe(
      (stateChanges) => {
        // we don't want to trigger this until after formly initialize the check expression
        // TODO(irvan): This if check should be removed if formly ever to change its post processing order.
        if (this.formlyWrapper.options.checkExpressions) {
          this.changeDetectorRef.detectChanges();
        }

        // we want to scroll to top of form when an error is detected.
        if (stateChanges === 'SET_ERRORS') {
          const element = this.componentTop.nativeElement as HTMLElement;
          element.scrollIntoView({ behavior: 'smooth' });
        }
      },
    );
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
