<ts-formly-ui
  [formlyWrapper]="formlyWrapper"
  (submitted)="submit($event)"
  [submitButtonConfig]="submitButtonConfig"
>
</ts-formly-ui>

<ion-button
  *ngIf="isResetButtonShown"
  (click)="reset()"
  color="danger"
  fill="clear"
>
  <ng-container *transloco="let t; scope: 'sharedForm'">
    {{ t('sharedForm.reset') }}
  </ng-container>
</ion-button>
