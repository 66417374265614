import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PromoProductLineLimit } from '@ts/promo/shared/util-product-line';

@Component({
  selector: 'ts-promo-product-line-limit-info-ui',
  templateUrl: './promo-product-line-limit-info-ui.component.html',
  styleUrls: ['./promo-product-line-limit-info-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoProductLineLimitInfoUiComponent {
  @Input() promoProductLineLimit!: PromoProductLineLimit;
}
