import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CashFlowLane } from '@ts/finance/cash-flow/shared/util-core';

@Component({
  selector: 'ts-finance-cash-flow-lane-mention-ui',
  templateUrl: './finance-cash-flow-lane-mention-ui.component.html',
  styleUrls: ['./finance-cash-flow-lane-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceCashFlowLaneMentionUiComponent {
  @Input() cashFlowLane!: CashFlowLane;
}
