import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { FacilityZone } from '@ts/facility/shared/util-core';

@Component({
  selector: 'ts-facility-zone-select-modal',
  templateUrl: './facility-zone-select-modal.component.html',
  styleUrls: ['./facility-zone-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityZoneSelectModalComponent {
  @Input() initial?: FacilityZone;

  constructor(private modalController: ModalController) {}

  facilitySelected(facilityZone: FacilityZone | null) {
    this.modalController.dismiss({ facilityZone });
  }
}
