<ng-container
  *ngIf="
    formGenericConfig.type === 'sidebar' &&
    (isScreenMinWidthLg || formGenericConfig.ignoreScreenSize)
  "
>
  <h3>{{ formGenericConfig.title }}</h3>
  <ts-form-flexible-sidebar-ui
    [config]="config"
    [initial]="initial"
    (submitted)="submitted.emit($event)"
  >
  </ts-form-flexible-sidebar-ui>
</ng-container>

<div
  class="button"
  *ngIf="
    formGenericConfig.type === 'button' &&
    (!isScreenMinWidthLg || formGenericConfig.ignoreScreenSize)
  "
>
  <ts-form-flexible-button-ui
    [config]="config"
    (submitted)="submitted.emit($event)"
    [initial]="initial"
    [buttonText]="formGenericConfig.buttonText"
    [modalTitle]="formGenericConfig.title"
  ></ts-form-flexible-button-ui>

  <ng-container *transloco="let t; scope: 'sharedForm'">
    <span *ngIf="initialLength">
      {{ initialLength }} {{ t('sharedForm.selected') }}
    </span>
  </ng-container>
</div>
