import { Injectable } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { PromoConditionType } from '@ts/promo/shared/util-core';

interface PromoConditionTypeOption {
  text: string;
  value: PromoConditionType;
}

const PROMO_CONDITION_TYPE_BASKET: PromoConditionType = 'basket';
const PROMO_CONDITION_TYPE_PRODUCT: PromoConditionType = 'product';
const PROMO_CONDITION_TYPE_SHIPPING: PromoConditionType = 'shipping';

@Injectable()
export class PromoConditionPresetProvider {
  PROMO_CONDITION_TYPES: {
    [promoConditionType in PromoConditionType]: string;
  } = {
    basket: 'Keranjang harus ada minimal...',
    product: 'Harus ada produk tertentu minimal...',
    shipping: 'Metoda pengiriman harus merupakan...',
  };

  getConfiguration(): FormlyFieldConfig {
    return {
      wrappers: ['card'],
      props: { label: 'Promo Condition' },
      type: undefined,
      fieldGroup: [
        {
          key: 'type',
          type: 'select',
          props: {
            label: 'Pilih jenis kondisi',
            options: [...this.getPromoConditionTypeOptions()],
            valueProp: 'value',
            labelProp: 'text',
            required: true,
          },
        },
        {
          fieldGroup: [
            {
              key: 'quantity_min',
              type: 'input',
              props: {
                required: true,
                type: 'number',
                label: 'Jumlah barang harus setidaknya...',
              },
            },
            {
              key: 'price_min',
              type: 'money',
              props: {
                required: true,
                label: 'Harga total harus setidaknya...',
              },
            },
          ],
          expressions: {
            hide: ({ model }) => {
              return model?.type !== PROMO_CONDITION_TYPE_BASKET;
            },
          },
        },
        {
          fieldGroup: [
            {
              key: 'quantity_min',
              type: 'input',
              props: {
                required: true,
                type: 'number',
                label: 'Jumlah barang harus setidaknya...',
              },
            },
            {
              key: 'price_min',
              type: 'money',
              props: {
                required: true,
                label: 'Harga total harus setidaknya...',
              },
            },
            {
              key: 'product_range',
              type: 'product-range',
              props: {
                required: true,
                label: 'Daftar barang',
              },
            },
          ],
          expressions: {
            hide: ({ model }) => {
              return model?.type !== PROMO_CONDITION_TYPE_PRODUCT;
            },
          },
        },
        {
          fieldGroup: [
            {
              key: 'shipping_methods',
              type: '#shippingMethodsSelect',
            },
          ],
          expressions: {
            hide: ({ model }) => {
              return model?.type !== PROMO_CONDITION_TYPE_SHIPPING;
            },
          },
        },
      ],
    };
  }

  private getPromoConditionTypeOptions(): readonly PromoConditionTypeOption[] {
    return Object.entries(this.PROMO_CONDITION_TYPES).map(([key, value]) => ({
      text: value,
      value: key as PromoConditionType,
    }));
  }
}
