import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { User } from '@ts/user/shared/util-core';

@Component({
  selector: 'ts-user-table-staff-body-ui',
  templateUrl: './user-table-staff-body-ui.component.html',
  styleUrls: ['./user-table-staff-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTableStaffBodyUiComponent {
  @Input() users!: readonly User[];

  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<User>>;
}
