import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { sprintf } from 'sprintf-js';

interface LogoConfigBase {
  name: string;
  alt: string;
}

export type LogoConfig = Readonly<LogoConfigBase>;

@Component({
  selector: 'ts-logo-ui',
  templateUrl: './logo-ui.component.html',
  styleUrls: ['./logo-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoUiComponent implements OnInit {
  @Input() logoConfig!: LogoConfig;

  logoUrl = '/assets/logo/img/%s.svg';

  ngOnInit() {
    this.logoUrl = sprintf(this.logoUrl, this.logoConfig.name);
  }
}
