<tr *ngFor="let cashFlowLane of cashFlowLanes">
  <td>
    <ts-finance-cash-storage-mention-ui
      [cashStorage]="cashFlowLane.cash_storage_origin"
    ></ts-finance-cash-storage-mention-ui>
  </td>
  <td>
    <ts-finance-cash-storage-mention-ui
      [cashStorage]="cashFlowLane.cash_storage_destination"
    ></ts-finance-cash-storage-mention-ui>
  </td>
  <td>
    <ts-timestamp-ui
      [timestamp]="cashFlowLane.date_created_timestamp"
    ></ts-timestamp-ui>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: cashFlowLane }"
    >
    </ng-template>
  </td>
</tr>
