import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { ProductRangeFormFieldComponent } from './product-range-form-field/product-range-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'product-range',
          component: ProductRangeFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [ProductRangeFormFieldComponent],
  exports: [ProductRangeFormFieldComponent],
})
export class ProductRangeFormFeatureFieldModule {}
