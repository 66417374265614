import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { Liability } from '@ts/finance/liability/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-finance-liability-table-body-ui',
  templateUrl: './finance-liability-table-body-ui.component.html',
  styleUrls: ['./finance-liability-table-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceLiabilityTableBodyUiComponent {
  @Input() liabilities!: readonly Liability[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<Liability>>;
}
