import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { strict as assert } from 'assert';
import { filter, Subscription, take, tap } from 'rxjs';

import { Store } from '@ngrx/store';
import { CategoryTreeService } from '@ts/category/list/data-access';
import { Category } from '@ts/category/shared/util-core';
import {
  initCategoryTreeSelect,
  selectCategoryTreeSelectState,
} from '@ts/category/tree/data-access';

@Component({
  selector: 'ts-category-tree-select',
  templateUrl: './category-tree-select.component.html',
  styleUrls: ['./category-tree-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTreeSelectComponent implements OnInit, OnDestroy {
  @Input() categoryInitial?: Category;

  @Output() categorySelected = new EventEmitter<Category>();

  /**
   * We want to wait loading the tree until we have initialized the category select process.
   * This may happen asynchronously, so we have to use a variable for this.
   */
  initialized = false;

  subscription?: Subscription;

  constructor(
    private categoryTreeService: CategoryTreeService,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.categoryInitial) {
      // we need to grab the ancestors, then use that to initialize the tree select process.
      this.categoryTreeService
        .getCategoryAncestors$(this.categoryInitial)
        .pipe(
          filter((ancestors) => !!ancestors),
          take(1),
          tap((ancestors) => {
            assert(ancestors);
            this.initialize(ancestors);
          }),
        )
        .subscribe();
    } else {
      // without initial category, we can initialize the process straight away.
      this.initialize();
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private initialize(ancestors?: readonly Category[]) {
    this.store.dispatch(initCategoryTreeSelect({ ancestors }));
    this.initialized = true;

    // Wait until category is selected, then output it
    this.subscription = this.store
      .select(selectCategoryTreeSelectState)
      .pipe(
        filter((state) => !!state?.categorySelected),
        take(1),
        tap((state) => {
          assert(state);
          assert(state.categorySelected);
          this.categorySelected.emit(state.categorySelected);
        }),
      )
      .subscribe();

    this.changeDetectorRef.detectChanges();
  }
}
