import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { PriceSellChangeSelectModalComponent } from '@ts/price/sell/action/feature-select';
import { PriceSellChange } from '@ts/price/sell/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-price-sell-change-form-field',
  templateUrl: './price-sell-change-form-field.component.html',
  styleUrls: ['./price-sell-change-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceSellChangeFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'priceSellChange';
  initial?: PriceSellChange;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<PriceSellChange>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: PriceSellChangeSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
