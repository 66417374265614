import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { PurchasingGroup } from '@ts/purchasing/shared/util-core';

@Component({
  selector: 'ts-purchasing-group-select-modal',
  templateUrl: './purchasing-group-select-modal.component.html',
  styleUrls: ['./purchasing-group-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingGroupSelectModalComponent {
  @Input() initial?: PurchasingGroup;

  constructor(private modalController: ModalController) {}

  purchasingGroupSelected(purchasingGroup: PurchasingGroup | null) {
    this.modalController.dismiss({ purchasingGroup });
  }
}
