import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ReturnToSupplier } from '@ts/return-to-supplier/shared/util-core';

@Component({
  selector: 'ts-return-to-supplier-mention-ui',
  templateUrl: './return-to-supplier-mention-ui.component.html',
  styleUrls: ['./return-to-supplier-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnToSupplierMentionUiComponent {
  @Input() returnToSupplier!: ReturnToSupplier;
}
