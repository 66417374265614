import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ReturnToSupplierPagingService } from '@ts/return-to-supplier/list/data-access';
import { ReturnToSupplier } from '@ts/return-to-supplier/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-return-to-supplier-list-infinite',
  templateUrl: './return-to-supplier-list-infinite.component.html',
  styleUrls: ['./return-to-supplier-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnToSupplierListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<
    ActionTemplateContext<ReturnToSupplier>
  >;

  constructor(
    public returnToSupplierPagingService: ReturnToSupplierPagingService,
  ) {}
}
