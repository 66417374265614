import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { Observable } from 'rxjs';

import { CategoryTreeService } from '@ts/category/list/data-access';
import { Category } from '@ts/category/shared/util-core';

@Component({
  selector: 'ts-category-tree-children',
  templateUrl: './category-tree-children.component.html',
  styleUrls: ['./category-tree-children.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryTreeChildrenComponent implements OnInit {
  @Input() category!: Category;
  children$!: Observable<readonly Category[] | null>;

  constructor(private categoryTreeService: CategoryTreeService) {}

  ngOnInit() {
    this.children$ = this.categoryTreeService.getCategoryChildren$(
      this.category,
    );
  }
}
