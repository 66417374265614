import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { CategoryIdService } from '@ts/category/detail/data-access';
import { Category } from '@ts/category/shared/util-core';
import { CategoryTreeSelectModalComponent } from '@ts/category/tree/feature-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

/**
 * Form field for category ids, instead of the actual Category object.
 * This is necessary because otherwise it will create a chain of category objects and db queries.
 */
@Component({
  selector: 'ts-category-id-form-field',
  templateUrl: './category-id-form-field.component.html',
  styleUrls: ['./category-id-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryIdFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'category';

  categoryInitial$?: Observable<Category | null>;

  constructor(private categoryIdService: CategoryIdService) {
    super();
  }

  ngOnInit() {
    const categoryIdInitial = this.formControl.value;
    if (categoryIdInitial) {
      this.categoryInitial$ = this.categoryIdService.fromId$(categoryIdInitial);
    }
  }

  categorySelected(category: Category | null) {
    this.formControl.setValue(category ? category.id : null);
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<Category>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: CategoryTreeSelectModalComponent,
        componentProps: {
          categoryInitial: entity,
        },
        backdropDismiss: false,
      };
    };
}
