import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { SharedLinkUiExpanderModule } from '@ts/shared/link/ui-expander';
import { SharedModalUiCoreModule } from '@ts/shared/modal/ui-core';
import { SharedPageUiCardModule } from '@ts/shared/page/ui-card';
import { SharedSearchbarFeatureCoreModule } from '@ts/shared/searchbar/feature-core';
import { SharedUiAwaitSpinnerModule } from '@ts/shared/ui-await-spinner';

import { CategoryTreeChildrenComponent } from './category-tree-children/category-tree-children.component';
import { CategoryTreeNodeComponent } from './category-tree-node/category-tree-node.component';
import { CategoryTreeSelectModalComponent } from './category-tree-select-modal/category-tree-select-modal.component';
import { CategoryTreeSelectComponent } from './category-tree-select/category-tree-select.component';
import { CategoryTreeComponent } from './category-tree/category-tree.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedUiAwaitSpinnerModule,
    SharedLinkUiExpanderModule,
    SharedSearchbarFeatureCoreModule,
    SharedModalUiCoreModule,
    SharedPageUiCardModule,
  ],
  declarations: [
    CategoryTreeNodeComponent,
    CategoryTreeChildrenComponent,
    CategoryTreeComponent,
    CategoryTreeSelectComponent,
    CategoryTreeSelectModalComponent,
  ],
  exports: [
    CategoryTreeNodeComponent,
    CategoryTreeChildrenComponent,
    CategoryTreeComponent,
    CategoryTreeSelectComponent,
    CategoryTreeSelectModalComponent,
  ],
})
export class CategoryTreeFeatureCoreModule {}
