import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { SharedMoneyUiModule } from '@ts/shared/money/ui';

import { PriceInlineUiComponent } from './price-inline-ui/price-inline-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, SharedMoneyUiModule],
  declarations: [PriceInlineUiComponent],
  exports: [PriceInlineUiComponent],
})
export class SharedPriceUiInlineModule {}
