import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'ts-repeat-formly-type-ui',
  templateUrl: './repeat-formly-type-ui.component.html',
  styleUrls: ['./repeat-formly-type-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepeatFormlyTypeUiComponent extends FieldArrayType {}
