import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { PromoProductLineUiDiscountInfoModule } from '@ts/promo/product-line/ui-discount-info';
import { PromoProductLineUiLimitInfoModule } from '@ts/promo/product-line/ui-limit-info';
import { PromoUiNumUsesRemainingModule } from '@ts/promo/ui-num-uses-remaining';
import { SharedLinkFeatureCoreModule } from '@ts/shared/link/feature-core';
import { SharedLinkUiCoreModule } from '@ts/shared/link/ui-core';
import { SharedMoneyUiModule } from '@ts/shared/money/ui';
import { SharedPriceUiInlineModule } from '@ts/shared/price/ui-inline';
import { SharedUiFractionAsPercentageModule } from '@ts/shared/ui-fraction-as-percentage';

import { ProductPriceTableUiComponent } from './product-price-table-ui/product-price-table-ui.component';
import { ProductPriceUiComponent } from './product-price-ui/product-price-ui.component';
import { ProductTitleUiComponent } from './product-title-ui/product-title-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedMoneyUiModule,
    SharedPriceUiInlineModule,
    SharedLinkUiCoreModule,
    SharedUiFractionAsPercentageModule,
    TranslocoModule,
    SharedLinkFeatureCoreModule,
    PromoUiNumUsesRemainingModule,
    PromoProductLineUiDiscountInfoModule,
    PromoProductLineUiLimitInfoModule,
  ],
  declarations: [
    ProductPriceUiComponent,
    ProductPriceTableUiComponent,
    ProductTitleUiComponent,
  ],
  exports: [
    ProductPriceUiComponent,
    ProductPriceTableUiComponent,
    ProductTitleUiComponent,
  ],
})
export class ProductUiTitlePriceModule {}
