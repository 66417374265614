import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { BasketIdOnly } from '@ts/basket/shared/data-access';
import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { User } from '@ts/user/shared/util-core';

// Unlike all the other id service, this does not use the entity's id.
// It instead use a separate entity id (user in this case).
// That's why we don't use ApiEntityIdAbstractService for this.
@Injectable({
  providedIn: 'root',
})
export class BasketIdStaffService {
  relativeUrl = 'api/basket/user/%d/basket_id/';

  constructor(private apiService: BormaDagoAuthenticatedApiConsumerService) {}

  /**
   * Parses user id into their basket
   */
  fromUserId$(userId: User['id']): Observable<BasketIdOnly> {
    return this.apiService.get$<BasketIdOnly>({
      relativeUrl: sprintf(this.relativeUrl, userId),
    });
  }
}
