import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TransportLane } from '@ts/transport/shared/util-core';

@Component({
  selector: 'ts-transport-lane-mention-ui',
  templateUrl: './transport-lane-mention-ui.component.html',
  styleUrls: ['./transport-lane-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportLaneMentionUiComponent {
  @Input() transportLane!: TransportLane;
}
