<ng-container *transloco="let t; scope: 'promo'">
  <span class="discount">
    <ts-money-ui
      [money]="promoProductLineDiscount.discount_amount"
    ></ts-money-ui>
    +
    <ts-fraction-as-percentage-ui
      [fraction]="promoProductLineDiscount.discount_fraction"
    ></ts-fraction-as-percentage-ui>
  </span>

  <span class="detail">
    {{
      t('promo.discountQuantityMultiple', {
        quantity: promoProductLineDiscount.discount_quantity_multiples
      })
    }}
  </span>
</ng-container>
