<table>
  <thead>
    <ts-finance-cash-storage-table-header-ui></ts-finance-cash-storage-table-header-ui>
  </thead>
  <tbody>
    <ts-finance-cash-storage-table-body-ui
      [cashStorages]="cashStorages"
      [actionTemplate]="actionTemplate"
    ></ts-finance-cash-storage-table-body-ui>
  </tbody>
</table>
