<ion-button
  type="submit"
  [disabled]="!isSubmittable"
  [color]="submitButtonConfig.color"
  expand="block"
  [ngClass]="getIonButtonClasses()"
>
  <ng-container *ngIf="isLoading">
    <ion-spinner></ion-spinner>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <ion-icon
      *ngIf="submitButtonConfig.ionIconName"
      slot="icon-only"
      [name]="submitButtonConfig.ionIconName"
    ></ion-icon>
    <ng-container *ngIf="submitDisplayedText">
      {{ submitDisplayedText | async }}
    </ng-container>
  </ng-container>
</ion-button>
