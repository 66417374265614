<ts-product-staff-list-infinite>
  <ng-template let-value="value" #actionTemplate>
    <ion-button
      (click)="productAdded.emit(value)"
      [disabled]="isDisabled(value)"
    >
      Tambah
    </ion-button>
  </ng-template>
</ts-product-staff-list-infinite>

<ts-product-range-selected-ui
  [productRange]="productRange"
  (productRemoved)="productRemoved.emit($event)"
>
</ts-product-range-selected-ui>
