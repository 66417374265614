import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PriceSellChange } from '@ts/price/sell/shared/util-core';

@Component({
  selector: 'ts-price-sell-change-mention-ui',
  templateUrl: './price-sell-change-mention-ui.component.html',
  styleUrls: ['./price-sell-change-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceSellChangeMentionUiComponent {
  @Input() priceSellChange!: PriceSellChange;
}
