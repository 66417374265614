import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { PromoUiNameDescriptionModule } from '@ts/promo/ui-name-description';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { PromoStaffFormFieldComponent } from './promo-staff-form-field/promo-staff-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    PromoUiNameDescriptionModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'promoStaff',
          component: PromoStaffFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [PromoStaffFormFieldComponent],
  exports: [PromoStaffFormFieldComponent],
})
export class PromoFormFeatureStaffFieldModule {}
