import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'ts-image-field-ui',
  templateUrl: './image-field-ui.component.html',
  styleUrls: ['./image-field-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageFieldUiComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  imagePreview?: string;
  imagesFormControl!: FieldType<FieldTypeConfig>['formControl'];

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.imagesFormControl = new FormControl<readonly File[] | null>(null);

    if (this.formControl.value) {
      this.imagePreview = this.formControl.value;
      this.formControl.setValue(undefined);
    } else if (this.formControl.value === null) {
      this.formControl.setValue(undefined);
    }
  }

  private imagePreviewSet(imagePreview?: string) {
    this.imagePreview = imagePreview;
    this.changeDetectorRef.detectChanges();
  }

  // for mocking FileReader
  getFileReader(): FileReader {
    return new FileReader();
  }

  imageChanged(event: Event) {
    const target = event.target as HTMLInputElement | undefined;
    const files = target?.files;
    if (!files || !files[0]) {
      this.formControl.setValue(undefined);
      this.imagePreviewSet(undefined);
    } else {
      const file = files[0];
      this.formControl.setValue(file);

      // Need to read the file in order to render it to the user as a preview
      const reader = this.getFileReader();
      reader.onload = () => {
        this.imagePreviewSet(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }
}
