<table *transloco="let t; scope: 'product'">
  <tr *ngIf="!isHasPromoDiscountQuantityMultiple">
    <th class="table-column-0">{{ t('product.price.obsolete') }}</th>
    <td class="table-column-1">
      <ts-price-inline-ui
        [price]="priceDiscountInfo.priceWithoutDiscount"
        class="obsolete"
      ></ts-price-inline-ui>
    </td>
  </tr>
  <tr>
    <th class="table-column-0">
      {{ t('product.price.current') }}
    </th>
    <td class="table-column-1" class="price-current">
      <ts-price-inline-ui
        *ngIf="isHasPromoDiscountQuantityMultiple"
        [price]="priceDiscountInfo.priceWithoutDiscount"
        class="price"
      ></ts-price-inline-ui>
      <ts-price-inline-ui
        *ngIf="!isHasPromoDiscountQuantityMultiple"
        [price]="priceDiscountInfo.priceWithDiscount"
        class="price"
      ></ts-price-inline-ui>
    </td>
  </tr>
  <tr>
    <th>{{ t('product.price.discount') }}</th>
    <td>
      <div *ngIf="!isHasPromoDiscountQuantityMultiple">
        <ts-promo-product-line-discount-info-ui
          [promoProductLineDiscount]="promoProductLine"
          [priceDiscountInfo]="priceDiscountInfo"
        ></ts-promo-product-line-discount-info-ui>
        <ts-link-ui
          [routerLinkParams]="['/shop/promo/detail', promoProductLine.promo_id]"
          routerDirection="forward"
          *transloco="let s; scope: 'common'"
        >
          {{ s('common.detail') }}
        </ts-link-ui>
      </div>
      <ts-promo-product-line-discount-info-ui
        *ngIf="isHasPromoDiscountQuantityMultiple"
        [promoProductLineDiscount]="promoProductLine"
      ></ts-promo-product-line-discount-info-ui>
    </td>
  </tr>
</table>
