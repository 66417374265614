<ng-container *ngIf="hasLink">
  <a
    class="container"
    [tsRouterLink]="['/shop/product', product.id, product.slug]"
    routerDirection="forward"
  >
    <div [ngTemplateOutlet]="thumbnailImg"></div>
  </a>
</ng-container>

<ng-container *ngIf="!hasLink">
  <div class="container">
    <div [ngTemplateOutlet]="thumbnailImg"></div>
  </div>
</ng-container>

<ng-template #thumbnailImg>
  <ion-img src="{{ productThumbnailUrl }}" [alt]="product.title"></ion-img>
</ng-template>
