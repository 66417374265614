<ts-pagination-infinite-table [pagingService]="purchasingGroupPagingService">
  <ts-purchasing-group-table-header-ui
    slot="header"
  ></ts-purchasing-group-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-purchasing-group-table-body-ui
      [purchasingGroups]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-purchasing-group-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
