import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { ReturnToSupplierUiMentionModule } from '@ts/return-to-supplier/ui-mention';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { ReturnToSupplierFormFieldComponent } from './return-to-supplier-form-field/return-to-supplier-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'return-to-supplier',
          component: ReturnToSupplierFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    ReturnToSupplierUiMentionModule,
  ],
  declarations: [ReturnToSupplierFormFieldComponent],
  exports: [ReturnToSupplierFormFieldComponent],
})
export class ReturnToSupplierFormFeatureFieldModule {}
