import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';
import { SharedUiAwaitSpinnerModule } from '@ts/shared/ui-await-spinner';

import { CategoryIdFormFieldComponent } from './category-id-form-field/category-id-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedUiAwaitSpinnerModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'category-id',
          component: CategoryIdFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [CategoryIdFormFieldComponent],
  exports: [CategoryIdFormFieldComponent],
})
export class CategoryFormFeatureFieldModule {}
