import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ReturnToSupplier } from '@ts/return-to-supplier/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-return-to-supplier-table-body-ui',
  templateUrl: './return-to-supplier-table-body-ui.component.html',
  styleUrls: ['./return-to-supplier-table-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnToSupplierTableBodyUiComponent {
  @Input() returnToSuppliers!: readonly ReturnToSupplier[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<
    ActionTemplateContext<ReturnToSupplier>
  >;
}
