import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { Category } from '@ts/category/shared/util-core';
import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { EntityPagingService } from '@ts/shared/pagination/data-access-entity';

@Injectable({
  providedIn: 'root',
})
export class CategorySearchStaffService {
  relativeUrl = 'api/category/staff/search/';

  constructor(
    private apiService: BormaDagoAuthenticatedApiConsumerService,
    private entityPagingService: EntityPagingService,
  ) {}

  search$(query: string): Observable<readonly Category[] | null> {
    return this.entityPagingService
      .getPaging$<Category>({
        apiService: this.apiService,
        relativeUrl: this.relativeUrl,
        queryParamsExtra: {
          q: query,
        },
      })
      .pipe(
        map((paging) => {
          if (paging) {
            return paging.entities;
          } else {
            return paging;
          }
        }),
      );
  }
}
