import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Supplier } from '@ts/supplier/shared/util-core';

@Component({
  selector: 'ts-supplier-select-modal',
  templateUrl: './supplier-select-modal.component.html',
  styleUrls: ['./supplier-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierSelectModalComponent {
  @Input() initial?: Supplier;

  constructor(private modalController: ModalController) {}

  supplierSelected(supplier: Supplier | null) {
    this.modalController.dismiss({ supplier });
  }
}
