import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { FormFlexibleConfig } from '@ts/shared/form/flexible/util-core';

@Component({
  selector: 'ts-form-flexible-sidebar-ui',
  templateUrl: './form-flexible-sidebar-ui.component.html',
  styleUrls: ['./form-flexible-sidebar-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFlexibleSidebarUiComponent<T, U> {
  @Input() config!: FormFlexibleConfig<T, U>;
  @Input() initial?: T;
  @Output() submitted = new EventEmitter<U>();
}
