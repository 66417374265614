import { BehaviorSubject, filter, Observable, switchMap, take } from 'rxjs';

import { ApiConsumerService } from '@ts/shared/api/data-access-api-consumer';
import { Paging } from '@ts/shared/pagination/util-core';

import { ApiEntityPagingService } from './api-entity-paging.model';
import { EntityPagingService } from './entity-paging.service';

type PagingParams = Pick<
  Parameters<EntityPagingService['getPaging$']>[0],
  'queryParamsExtra'
>;

/**
 * Abstracts services that uses api service to retrieve paginated entity.
 *
 * @example
 *
 * ```
 * class ProductEntityPagingService extends ApiEntityPagingAbstractService<Product> {
 *   relativeUrl$ = of(...)
 *
 *   constructor(
 *     protected override apiService: BormaDago...,
 *     protected override entityPagingService
 *   ) {
 *     super();
 *   }
 * }
 * ```
 */
export abstract class ApiEntityPagingAbstractService<T>
  implements ApiEntityPagingService<T>
{
  abstract relativeUrl$: Observable<string>;

  protected abstract apiService: ApiConsumerService;
  protected abstract entityPagingService: EntityPagingService;

  refresh$ = new BehaviorSubject<null>(null);

  getPaging$(params: PagingParams = {}): Observable<Paging<T> | null> {
    return this.refresh$.pipe(
      switchMap(() =>
        this.relativeUrl$.pipe(
          take(1),
          switchMap((relativeUrl) =>
            this.entityPagingService.getPaging$<T>({
              ...params,
              relativeUrl: relativeUrl,
              apiService: this.apiService,
            }),
          ),
        ),
      ),
    );
  }

  getPagingTruthy$(params: PagingParams = {}): Observable<Paging<T>> {
    return this.getPaging$(params).pipe(
      filter((entity: Paging<T> | null): entity is Paging<T> => !!entity),
    );
  }

  refresh(): void {
    this.refresh$.next(null);
  }
}
