import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { FormFlexibleConfig } from '@ts/shared/form/flexible/util-core';

@Component({
  selector: 'ts-form-flexible-modal-ui',
  templateUrl: './form-flexible-modal-ui.component.html',
  styleUrls: ['./form-flexible-modal-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFlexibleModalUiComponent<T, U = T> {
  @Input() title!: string;
  @Input() config!: FormFlexibleConfig<T, U>;
  @Input() initial?: T;

  constructor(private modalController: ModalController) {}

  submit(data: U) {
    this.modalController.dismiss({ data });
  }
}
