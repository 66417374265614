import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Liability } from '@ts/finance/liability/shared/util-core';

@Component({
  selector: 'ts-finance-liability-select-modal',
  templateUrl: './finance-liability-select-modal.component.html',
  styleUrls: ['./finance-liability-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceLiabilitySelectModalComponent {
  @Input() initial?: Liability;

  constructor(private modalController: ModalController) {}

  liabilitySelected(liability: Liability | null) {
    this.modalController.dismiss({ liability });
  }
}
