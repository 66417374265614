import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlySelectOption } from '@ngx-formly/core/select';

@Component({
  selector: 'ts-button-select-form-field-ui',
  templateUrl: './button-select-form-field-ui.component.html',
  styleUrls: ['./button-select-form-field-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSelectFormFieldUiComponent extends FieldType<FieldTypeConfig> {
  optionSelect(option: FormlySelectOption) {
    this.formControl.setValue(option.value);
  }

  getClass(option: FormlySelectOption) {
    return option.value === this.formControl.value ? 'selected' : '';
  }
}
