import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FinanceCashStorageMentionUiComponent } from './finance-cash-storage-mention-ui/finance-cash-storage-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [FinanceCashStorageMentionUiComponent],
  exports: [FinanceCashStorageMentionUiComponent],
})
export class FinanceCashStorageUiMentionModule {}
