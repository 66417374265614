<ng-container *ngIf="hasLink">
  <a
    [tsRouterLink]="['/shop/product', product.id, product.slug]"
    routerDirection="forward"
  >
    <div [ngTemplateOutlet]="title"></div>
  </a>
</ng-container>

<ng-container *ngIf="!hasLink">
  <div [ngTemplateOutlet]="title"></div>
</ng-container>

<ng-template #title>
  <div
    title="{{ product.title | titlecase }} [{{ product.upc }}] {{
      product.slug
    }}"
    class="title"
  >
    {{ product.title | titlecase }}
  </div>
  <ng-content></ng-content>
</ng-template>
