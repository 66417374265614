import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { PromoProductLineDiscount } from '@ts/promo/shared/util-product-line';
import { PriceDiscountInfo } from '@ts/shared/price/util-core';

@Component({
  selector: 'ts-product-price-table-ui',
  templateUrl: './product-price-table-ui.component.html',
  styleUrls: ['./product-price-table-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPriceTableUiComponent implements OnInit {
  @Input() promoProductLine!: PromoProductLineDiscount;
  @Input() priceDiscountInfo!: PriceDiscountInfo;

  isHasPromoDiscountQuantityMultiple = false;

  ngOnInit() {
    if (this.promoProductLine.discount_quantity_multiples > 1) {
      this.isHasPromoDiscountQuantityMultiple = true;
    }
  }
}
