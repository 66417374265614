<ts-form-field-select-with-modal-ui
  [modalShowParamsCallback]="modalShowParamsCallback"
  [modalOutputPropertyName]="modalOutputPropertyName"
  [formControl]="formControl"
  [initial]="initial"
>
  <ng-template let-entity="entity" #entityTemplate>
    <ts-finance-liability-mention-ui
      [liability]="entity"
    ></ts-finance-liability-mention-ui>
  </ng-template>
</ts-form-field-select-with-modal-ui>
