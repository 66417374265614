<table>
  <thead>
    <ts-return-to-supplier-table-header-ui></ts-return-to-supplier-table-header-ui>
  </thead>
  <tbody>
    <ts-return-to-supplier-table-body-ui
      [returnToSuppliers]="returnToSuppliers"
      [actionTemplate]="actionTemplate"
    ></ts-return-to-supplier-table-body-ui>
  </tbody>
</table>
