import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import {
  PRODUCT_SEARCH_QUERY_PARAMS_FILTER_FORM_FIELDS,
  PRODUCT_SEARCH_STAFF_QUERY_PARAMS_FILTER_FORM_FIELDS,
  ProductFilterQueryParams,
  ProductFilterQueryParamsFormModel,
} from '@ts/product/filter/util-core';
import { FormFlexibleConfig } from '@ts/shared/form/flexible/util-core';
import {
  CURRENCY_DEFAULT,
  MoneyOperationService,
} from '@ts/shared/money/util-core';
import { QueryParamsService } from '@ts/shared/router/data-access-params';

export interface ProductFilterFormConfigParams {
  showCategory?: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class ProductFilterService {
  constructor(
    private queryParamsService: QueryParamsService,
    private moneyOperationService: MoneyOperationService,
  ) {}

  getFormFlexibleConfig(
    params?: ProductFilterFormConfigParams,
  ): FormFlexibleConfig<
    ProductFilterQueryParamsFormModel,
    ProductFilterQueryParams
  > {
    return {
      fields: params?.showCategory
        ? PRODUCT_SEARCH_STAFF_QUERY_PARAMS_FILTER_FORM_FIELDS
        : PRODUCT_SEARCH_QUERY_PARAMS_FILTER_FORM_FIELDS,
      transformCallback: this.getTransformCallback(),
    };
  }

  getFormFlexibleInitial$(): Observable<ProductFilterQueryParamsFormModel> {
    return this.queryParamsService.getQueryParams$().pipe(
      map((queryParams: Record<string, string>) => {
        let params: ProductFilterQueryParamsFormModel = {
          ...queryParams,
        };
        if (params['price__gte']) {
          params = {
            ...params,
            price__gte: this.moneyOperationService.numberToMoney(
              parseFloat(queryParams['price__gte']),
              CURRENCY_DEFAULT,
            ),
          };
        }
        if (params['price__lte']) {
          params = {
            ...params,
            price__lte: this.moneyOperationService.numberToMoney(
              parseFloat(queryParams['price__lte']),
              CURRENCY_DEFAULT,
            ),
          };
        }

        return params;
      }),
    );
  }

  private getTransformCallback() {
    return (value: ProductFilterQueryParamsFormModel) => {
      return {
        is_promo_active: value.is_promo_active || undefined,
        category: value.category || undefined,
        price__gte: value.price__gte
          ? parseInt(value.price__gte.amount, 10)
          : undefined,
        price__lte: value.price__lte
          ? parseInt(value.price__lte.amount, 10)
          : undefined,
      };
    };
  }
}
