import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { UserPagingStaffService } from '@ts/user/list/data-access';
import { User } from '@ts/user/shared/util-core';

@Component({
  selector: 'ts-user-staff-list-infinite',
  templateUrl: './user-staff-list-infinite.component.html',
  styleUrls: ['./user-staff-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserStaffListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<User>>;

  constructor(public userPagingStaffService: UserPagingStaffService) {}
}
