import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FinanceLiabilitySelectModalComponent } from '@ts/finance/liability/action/feature-select';
import { Liability } from '@ts/finance/liability/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-finance-liability-form-field',
  templateUrl: './finance-liability-form-field.component.html',
  styleUrls: ['./finance-liability-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceLiabilityFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'liability';
  initial?: Liability;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<Liability>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: FinanceLiabilitySelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
