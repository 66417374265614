import { Injectable } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable()
export class PromoCommonPresetProvider {
  getConfiguration(): FormlyFieldConfig {
    return {
      wrappers: ['card'],
      props: { label: 'Promo Info' },
      type: undefined,
      fieldGroup: [
        {
          key: 'name',
          type: 'input',
          props: {
            label: 'Nama (internal)',
            description:
              'Nama ini hanya akan ditampilkan ke staff, tidak ke user.',
            required: true,
          },
        },
        {
          key: 'name_en',
          type: 'input',
          props: {
            label: 'Judul (English)',
            required: true,
          },
        },
        {
          key: 'name_id',
          type: 'input',
          props: {
            label: 'Judul (Indonesia)',
            required: true,
          },
        },
        {
          key: 'date_start_timestamp',
          type: 'date-time',
          props: {
            label: 'Tanggal pertama mulai',
            required: true,
            showDefaultButton: true,
            presentation: 'date-time',
          },
        },
        {
          key: 'date_end_timestamp',
          type: 'date-time',
          props: {
            label: 'Tanggal terakhir berlaku',
            required: true,
            showDefaultButton: true,
            presentation: 'date-time',
          },
        },
        {
          key: 'description_en',
          type: 'text-editor',
          props: {
            label: 'Deskripsi (English)',
            required: false,
          },
        },
        {
          key: 'description_id',
          type: 'text-editor',
          props: {
            label: 'Deskripsi (Indonesia)',
            required: false,
          },
        },
        {
          key: 'is_advertised',
          type: 'checkbox',
          defaultValue: true,
          props: {
            label: 'Tampilkan di daftar promo',
            description:
              'Apabila tidak dicurek, promo ini hanya dapat dipakai dengan memasukkan kode.',
            required: false,
          },
        },
        {
          key: 'supplier',
          type: 'supplier',
          props: {
            label: 'Supplier',
            description:
              'Supplier hanya digunakan untuk laporan, misalnya untuk mencari promo dari supplier tertentu.',
            required: false,
          },
        },
      ],
    };
  }
}
