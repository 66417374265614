import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { PricePurchaseChangeMentionUiComponent } from './price-purchase-change-mention-ui/price-purchase-change-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [PricePurchaseChangeMentionUiComponent],
  exports: [PricePurchaseChangeMentionUiComponent],
})
export class PricePurchaseUiMentionModule {}
