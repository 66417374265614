import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { Product } from '@ts/product/shared/util-core';

@Component({
  selector: 'ts-product-thumbnail-ui',
  templateUrl: './product-thumbnail-ui.component.html',
  styleUrls: ['./product-thumbnail-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductThumbnailUiComponent implements OnChanges {
  @Input() product!: Product;
  @Input() hasLink = false;
  productThumbnailUrl!: string;

  productImagePlaceholderUrl = '/assets/product/img/imagePlaceholder.jpg';

  ngOnChanges() {
    if (this.product.thumbnail_url) {
      this.productThumbnailUrl = this.product.thumbnail_url;
    } else {
      this.productThumbnailUrl = this.productImagePlaceholderUrl;
    }
  }
}
