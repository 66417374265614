import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavController } from '@ionic/angular';

import { SearchbarComponent } from '../searchbar/searchbar.component';

@Component({
  selector: 'ts-searchbar-query-param',
  templateUrl: './searchbar-query-param.component.html',
  styleUrls: ['./searchbar-query-param.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchbarQueryParamComponent {
  @Input() navigateParams!: readonly string[];
  autocompleteCallback?: SearchbarComponent['autocompleteCallback'];

  queryParamKey = 'q';

  constructor(protected navController: NavController) {}

  searched(searchString: string) {
    this.navController.navigateRoot([...this.navigateParams], {
      queryParams: {
        [this.queryParamKey]: searchString,
      },
    });
  }
}
