import { FormControlStatus, FormGroup } from '@angular/forms';

import { merge, Observable, Subject } from 'rxjs';

import { logger } from '@ts/shared/util-logging';

// makes isLoading readonly on public, writable in private.
interface FormWrapperPublicReadonly {
  isLoading: Readonly<boolean>;
}

type FormWrapperInternalStateChanges =
  | 'SET_ERRORS'
  | 'REENABLE'
  | 'SET_LOADING';

export type FormWrapperStateChanges =
  | FormControlStatus
  | FormWrapperInternalStateChanges;

/**
 *
 * This wrap form display, and can be used without using formly
 *
 */
export class FormWrapper implements FormWrapperPublicReadonly {
  NON_FIELD_ERRORS_KEY = 'non_field_errors';

  /**
   * Whether this form submission is being processed. This will disable the form.
   *
   * This is usually set to true automatically after the form is submitted to prevent
   * double submission.
   *
   * If you want the form to be re-enabled, use .reenable().
   */
  isLoading = false;
  private previousStatus: Record<string, FormControlStatus> = {};

  private internalStateChanges$ =
    new Subject<FormWrapperInternalStateChanges>();

  /**
   * Emits every time the form state changes, i.e, "loading", "submittable", and errors.
   */
  stateChanges$: Observable<FormWrapperStateChanges>;

  constructor(public formGroup: FormGroup) {
    this.stateChanges$ = merge(
      this.internalStateChanges$,
      formGroup.statusChanges,
    );
  }

  setLoading() {
    this.isLoading = true;
    // we can't use formGroup.disable(), because it removes the errors, so we have to write our own.
    for (const [key, control] of Object.entries(this.formGroup.controls)) {
      if (control.status !== 'DISABLED') {
        this.previousStatus[key] = control.status;
        (control as { status: FormControlStatus }).status = 'DISABLED';
      }
    }
    this.internalStateChanges$.next('SET_LOADING');
  }

  reenable() {
    this.isLoading = false;
    // see comment in setLoading
    for (const [key, status] of Object.entries(this.previousStatus)) {
      const control = this.formGroup.controls[key];
      if (control.status === 'DISABLED') {
        // the error can be set while the form was disabled.
        if (control.errors) {
          (control as { status: FormControlStatus }).status = 'INVALID';
        } else {
          (control as { status: FormControlStatus }).status = status;
        }
      }
    }
    this.previousStatus = {};
    this.internalStateChanges$.next('REENABLE');
  }

  isSubmittable(): boolean {
    return !this.isLoading && this.formGroup.valid;
  }

  /**
   * Explicitly set errors based on some error mapping.
   *
   * @example
   * ```
   * api.post$(...).catchError(error => formWrapper.setErrors(error.error))
   * ```
   */
  setErrors(errors: Readonly<Record<string, string[]>>) {
    const nonFieldErrors = [];
    for (const [errorKey, errorTexts] of Object.entries(errors)) {
      const control = this.formGroup.controls[errorKey];
      if (control) {
        control.setErrors({ other: errorTexts });
        control.markAsTouched();
      } else {
        if (errorKey === this.NON_FIELD_ERRORS_KEY) {
          nonFieldErrors.push(...errorTexts);
        } else {
          logger.error(
            `Error encountered during form validation: key ${errorKey} is not found in form. ` +
              `Form contains: ${Object.keys(this.formGroup.controls)}. ` +
              `Errors: ${errors}.`,
          );
          nonFieldErrors.push(
            ...errorTexts.map(
              (errorText) => `${errorKey}: ${JSON.stringify(errorText)}`,
            ),
          );
        }
      }
    }

    if (nonFieldErrors.length) {
      this.formGroup.setErrors({ other: nonFieldErrors });
      this.formGroup.markAsTouched();
    }

    this.internalStateChanges$.next('SET_ERRORS');
  }
}
