import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PageCardUiComponent } from './page-card-ui/page-card-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [PageCardUiComponent],
  exports: [PageCardUiComponent],
})
export class SharedPageUiCardModule {}
