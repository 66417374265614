import { omit } from 'lodash';
import { Observable, of, OperatorFunction, switchMap, take } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { ApiConsumerService } from '@ts/shared/api/data-access-api-consumer';

import { EntityIdService } from './entity-id.model';

/**
 * Abstracts retrieving entity from its id and some helper functions.
 *
 * @example
 *
 * ```
 * @Injectable({
 *   providedIn: 'root',
 * })
 * export class ProductIdService extends ApiEntityIdAbstractService<Product> {
 *   relativeUrl = 'some/url/%d'
 *
 *   constructor(
 *     protected override apiService: BormaDagoAuthenticatedApiConsumerService
 *   ) {
 *     super();
 *   }
 * }
 * ```
 */
export abstract class ApiEntityIdAbstractService<T extends { id: number }>
  implements EntityIdService<T>
{
  abstract relativeUrl: string;
  protected abstract apiService: ApiConsumerService;

  fromId$(id: number): Observable<T> {
    return this.apiService.get$<T>({
      relativeUrl: sprintf(this.relativeUrl, id),
    });
  }

  /**
   * @param previous_key key of the id in the original object, e.g., "product_id"
   * @param new_key of the entity in the final object, e.g., "user"
   */
  idToEntityPropertyReplaceOperator<
    U extends Object & { [P in V]?: number },
    V extends string,
    W extends string,
  >(
    id_key: V,
    entity_key: W,
  ): OperatorFunction<U, Omit<U, V> | { [P in W]?: T }> {
    return switchMap((object) => {
      if (object[id_key]) {
        return this.fromId$(object[id_key] as number).pipe(
          take(1),
          switchMap((user) => {
            return of({
              ...omit(object, [id_key]),
              [entity_key]: user,
            });
          }),
        );
      } else {
        return of(object);
      }
    });
  }

  entityToIdPropertyReplace<
    U extends Object & { [P in W]?: T },
    V extends string,
    W extends string,
  >(object: U, id_key: V, entity_key: W): Omit<U, W> | { [P in V]?: number } {
    const entity = object[entity_key];
    return {
      [id_key]: entity?.id,
      ...omit(object, [entity_key]),
    };
  }
}
