import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { PricePurchaseChange } from '@ts/price/purchase/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-price-purchase-change-table-body-ui',
  templateUrl: './price-purchase-change-table-body-ui.component.html',
  styleUrls: ['./price-purchase-change-table-body-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePurchaseChangeTableBodyUiComponent {
  @Input() pricePurchaseChanges!: readonly PricePurchaseChange[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @Input() actionTemplate?: TemplateRef<
    ActionTemplateContext<PricePurchaseChange>
  >;
}
