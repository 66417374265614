import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { CashStorage } from '@ts/finance/cash-storage/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-finance-cash-storage-table-ui',
  templateUrl: './finance-cash-storage-table-ui.component.html',
  styleUrls: ['./finance-cash-storage-table-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceCashStorageTableUiComponent {
  @Input() cashStorages!: readonly CashStorage[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @ContentChild('actionTemplate') actionTemplate?: TemplateRef<
    ActionTemplateContext<CashStorage>
  >;
}
