import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { ProductStaff } from '@ts/product/shared/util-core';
import { BormaDagoAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import {
  ApiEntityPagingAbstractService,
  EntityPagingService,
} from '@ts/shared/pagination/data-access-entity';

@Injectable({
  providedIn: 'root',
})
export class ProductStaffPagingService extends ApiEntityPagingAbstractService<ProductStaff> {
  relativeUrl$ = of('api/product/staff/search/');

  constructor(
    protected override apiService: BormaDagoAuthenticatedApiConsumerService,
    protected override entityPagingService: EntityPagingService,
  ) {
    super();
  }
}
