import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import {
  Money,
  MoneyDisplay,
  MoneyFormatService,
  MoneyOperationService,
} from '@ts/shared/money/util-core';

@Component({
  selector: 'ts-money-ui',
  templateUrl: './money-ui.component.html',
  styleUrls: ['./money-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyUiComponent implements OnChanges {
  @Input() money!: Money;

  moneyDisplay!: MoneyDisplay;
  isMoneyDecimalZero!: boolean;

  constructor(
    private moneyFormatService: MoneyFormatService,
    private moneyOperationService: MoneyOperationService,
  ) {}

  ngOnChanges() {
    this.moneyDisplay = this.moneyFormatService.getMoneyDisplay(this.money);
    this.isMoneyDecimalZero = !this.moneyOperationService.isMoneyDecimalZero(
      this.money,
    );
  }
}
