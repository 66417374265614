import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

import { Product } from '@ts/product/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-product-table-staff-ui',
  templateUrl: './product-table-staff-ui.component.html',
  styleUrls: ['./product-table-staff-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTableStaffUiComponent {
  @Input() products!: readonly Product[];

  /**
   * May not be provided, if the table does not have any action.
   */
  @ContentChild('actionTemplate') actionTemplate?: TemplateRef<
    ActionTemplateContext<Product>
  >;
}
