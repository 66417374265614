import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { FormWrapper } from '@ts/shared/form/util-core';

@Component({
  selector: 'ts-form-scaffold-ui',
  templateUrl: './form-scaffold-ui.component.html',
  styleUrls: ['./form-scaffold-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormScaffoldUiComponent implements OnInit, OnDestroy {
  @Input() formWrapper!: FormWrapper;

  /**
   * Passes the model from the formWrapper as parameter on submit.
   *
   * Whenever this is emitted, formWrapper.isDisabled will be set to true.
   * You can reset this manually to false if needed.
   */
  @Output() submitted = new EventEmitter();

  subscription?: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscription = this.formWrapper.stateChanges$.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  submitPressed() {
    if (this.formWrapper.isSubmittable()) {
      this.formWrapper.setLoading();
      this.submitted.emit(this.formWrapper.formGroup.value);
    }
  }
}
