import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'ts-ion-form-non-field-errors-ui',
  templateUrl: './ion-form-non-field-errors-ui.component.html',
  styleUrls: ['./ion-form-non-field-errors-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IonFormNonFieldErrorsUiComponent implements OnChanges {
  @Input() errors!: ValidationErrors | null;

  showErrors!: boolean;
  errorTexts!: readonly string[];

  ngOnChanges() {
    if (!this.errors) {
      this.showErrors = false;
    } else {
      this.showErrors = true;
      const errorTextsAll = [];
      for (const errorTexts of Object.values(this.errors)) {
        errorTextsAll.push(...errorTexts);
      }
      this.errorTexts = errorTextsAll;
    }
  }
}
