import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CashStorage } from '@ts/finance/cash-storage/shared/util-core';

@Component({
  selector: 'ts-finance-cash-storage-mention-ui',
  templateUrl: './finance-cash-storage-mention-ui.component.html',
  styleUrls: ['./finance-cash-storage-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceCashStorageMentionUiComponent {
  @Input() cashStorage!: CashStorage;
}
