import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Product, ProductRange } from '@ts/product/shared/util-core';

@Component({
  selector: 'ts-product-range-selected-ui',
  templateUrl: './product-range-selected-ui.component.html',
  styleUrls: ['./product-range-selected-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRangeSelectedUiComponent {
  @Input() productRange!: ProductRange;
  @Output() productRemoved = new EventEmitter<Product>();
}
