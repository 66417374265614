import { strict as assert } from 'assert';

import { createReducer, on } from '@ngrx/store';
import { CategoryTreeSelectState } from '@ts/category/tree/shared/util-core';

import {
  categoryTreeSelect,
  initCategoryTreeSelect,
  noOpenChildCategoriesInitialSet,
} from './category-tree-select.action';

export type State = CategoryTreeSelectState | null;

export const initialState: State = null;

export const categoryTreeSelectReducer = createReducer<State>(
  initialState,
  on(initCategoryTreeSelect, (state, { ancestors }) => {
    return {
      ancestorsInitial: ancestors,
    };
  }),
  on(categoryTreeSelect, (state, { category }) => {
    assert(state);
    return {
      ...state,
      categorySelected: category,
    };
  }),
  on(
    noOpenChildCategoriesInitialSet,
    (state, { noOpenChildCategoriesInitial }) => {
      assert(state);
      return {
        ...state,
        noOpenChildCategoriesInitial,
      };
    },
  ),
);
