import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { SupplierPagingStaffService } from '@ts/supplier/list/data-access';
import { Supplier } from '@ts/supplier/shared/util-core';

@Component({
  selector: 'ts-supplier-staff-list-infinite',
  templateUrl: './supplier-staff-list-infinite.component.html',
  styleUrls: ['./supplier-staff-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierStaffListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<Supplier>>;

  constructor(public supplierPagingStaffService: SupplierPagingStaffService) {}
}
