import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FinanceCashStorageSelectModalComponent } from '@ts/finance/cash-storage/action/feature-select';
import { CashStorage } from '@ts/finance/cash-storage/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-finance-cash-storage-form-field',
  templateUrl: './finance-cash-storage-form-field.component.html',
  styleUrls: ['./finance-cash-storage-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceCashStorageFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'cashStorage';
  initial?: CashStorage;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<CashStorage>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: FinanceCashStorageSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
