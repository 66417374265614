import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { PurchasingGroupSelectModalComponent } from '@ts/purchasing/group/action/feature-select';
import { PurchasingGroup } from '@ts/purchasing/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-purchasing-group-form-field',
  templateUrl: './purchasing-group-form-field.component.html',
  styleUrls: ['./purchasing-group-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchasingGroupFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'purchasingGroup';
  initial?: PurchasingGroup;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<PurchasingGroup>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: PurchasingGroupSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
