<table>
  <thead>
    <ts-promo-staff-table-header-ui></ts-promo-staff-table-header-ui>
  </thead>
  <tbody>
    <ts-promo-staff-table-body-ui
      [promoStaffs]="promoStaffs"
      [actionTemplate]="actionTemplate"
    ></ts-promo-staff-table-body-ui>
  </tbody>
</table>
