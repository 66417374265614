import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';
import { SupplierSelectModalComponent } from '@ts/supplier/feature-select';
import { Supplier } from '@ts/supplier/shared/util-core';

@Component({
  selector: 'ts-supplier-form-field',
  templateUrl: './supplier-form-field.component.html',
  styleUrls: ['./supplier-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'supplier';
  initial?: Supplier | undefined;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<Supplier>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: SupplierSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
