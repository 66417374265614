import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { TransportLanePagingService } from '@ts/transport/lane/list/data-access';
import { TransportLane } from '@ts/transport/shared/util-core';

@Component({
  selector: 'ts-transport-lane-list-infinite',
  templateUrl: './transport-lane-list-infinite.component.html',
  styleUrls: ['./transport-lane-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportLaneListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<TransportLane>>;

  constructor(public transportLanePagingService: TransportLanePagingService) {}
}
