<div class="container">
  <ng-container *transloco="let t; scope: 'common'">
    <form [formGroup]="searchForm" (ngSubmit)="search()">
      <ion-input
        [debounce]="debounceTimeMs"
        [clearInput]="true"
        formControlName="searchString"
        placeholder="{{ t('common.search') }}..."
        (ionChange)="inputChanged()"
        (ionFocus)="focused()"
        (ionBlur)="unfocused()"
        (keydown)="keyPressed($event)"
      ></ion-input>
      <ts-form-submit-button-ui
        [isInheritStyle]="true"
        [isLoading]="false"
        [isSubmittable]="true"
        [submitButtonConfig]="{
          ionIconName: 'search-outline',
          color: 'alter-tertiary'
        }"
      ></ts-form-submit-button-ui>
    </form>
  </ng-container>
  <ion-list *ngIf="autocompleteSuggestions.length && isFocused" lines="none">
    <!-- We use (mousedown) instead of (click) so it triggers before ionBlur fires -->
    <ion-item
      button
      *ngFor="let autocompleteSuggestion of autocompleteSuggestions"
      (mousedown)="autocompleteSuggestionClicked(autocompleteSuggestion)"
      [ngClass]="getAutocompleteSuggestionItemClasses(autocompleteSuggestion)"
    >
      <ion-label>
        {{ autocompleteSuggestion }}
      </ion-label>
    </ion-item>
  </ion-list>
</div>
