import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Transport } from '@ts/transport/shared/util-core';

@Component({
  selector: 'ts-transport-select-modal',
  templateUrl: './transport-select-modal.component.html',
  styleUrls: ['./transport-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportSelectModalComponent {
  @Input() initial?: Transport;

  constructor(private modalController: ModalController) {}

  transportSelected(transport: Transport | null) {
    this.modalController.dismiss({ transport });
  }
}
