<ts-pagination-infinite-table
  [pagingService]="pricePurchaseChangePagingService"
>
  <ts-price-purchase-change-table-header-ui
    slot="header"
  ></ts-price-purchase-change-table-header-ui>

  <ng-template let-paging="paging" #pagingTemplate>
    <ts-price-purchase-change-table-body-ui
      [pricePurchaseChanges]="paging.entities"
      [actionTemplate]="actionTemplate"
    ></ts-price-purchase-change-table-body-ui>
  </ng-template>
</ts-pagination-infinite-table>
