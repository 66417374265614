import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';
import { UserSelectModalComponent } from '@ts/user/feature-select';
import { User } from '@ts/user/shared/util-core';

@Component({
  selector: 'ts-user-form-field',
  templateUrl: './user-form-field.component.html',
  styleUrls: ['./user-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'user';
  initial?: User;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<User>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: UserSelectModalComponent,
        componentProps: { initial: entity },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
