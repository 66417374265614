import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { PromoStaffPagingService } from '@ts/promo/list/data-access';
import { PromoStaff } from '@ts/promo/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-promo-staff-list-infinite',
  templateUrl: './promo-staff-list-infinite.component.html',
  styleUrls: ['./promo-staff-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoStaffListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<PromoStaff>>;

  constructor(public promoStaffPagingService: PromoStaffPagingService) {}
}
