import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { PriceSellChange } from '@ts/price/sell/shared/util-core';

@Component({
  selector: 'ts-price-sell-change-select-modal',
  templateUrl: './price-sell-change-select-modal.component.html',
  styleUrls: ['./price-sell-change-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceSellChangeSelectModalComponent {
  @Input() initial?: PriceSellChange;

  constructor(private modalController: ModalController) {}

  priceSellChangeSelected(priceSellChange: PriceSellChange | null) {
    this.modalController.dismiss({ priceSellChange });
  }
}
