<tr>
  <th>ID</th>
  <th>Produk</th>
  <th>Supplier</th>
  <th>Harga</th>
  <th>Harga Dasar</th>
  <th>Discount</th>
  <th>Persentase Pajak</th>
  <th>Aksi</th>
</tr>
