import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { SharedFormUiDateTimeModule } from '@ts/shared/form/ui-date-time';
import { SharedFormUiTextEditorModule } from '@ts/shared/form/ui-text-editor';
import { SharedFormWrapperUiCardModule } from '@ts/shared/form/wrapper/ui-card';
import { SupplierFormFeatureFieldModule } from '@ts/supplier/form/feature-field';

import { PromoCommonPresetProvider } from './promo-common.preset';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlyPresetModule,
    FormlyModule.forChild({
      presets: [
        {
          name: 'promoCommon',
          config: new PromoCommonPresetProvider(),
        },
      ],
    }),
    SharedFormWrapperUiCardModule,
    SharedFormUiDateTimeModule,
    SharedFormUiTextEditorModule,
    SupplierFormFeatureFieldModule,
  ],
})
export class PromoActionUiFormCommonModule {}
