import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { MoneyUiComponent } from './money-ui/money-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [MoneyUiComponent],
  exports: [MoneyUiComponent],
})
export class SharedMoneyUiModule {}
