import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Product, ProductRange } from '@ts/product/shared/util-core';

@Component({
  selector: 'ts-product-range-select',
  templateUrl: './product-range-select.component.html',
  styleUrls: ['./product-range-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductRangeSelectComponent {
  @Input() productRange!: ProductRange;
  @Output() productRangeSelected = new EventEmitter<ProductRange>();

  selectProductRange() {
    this.productRangeSelected.emit(this.productRange);
  }

  removeProduct(productToRemove: Product) {
    const updatedProducts = this.productRange.products.filter(
      (product) => product.id !== productToRemove.id,
    );

    this.productRange = {
      ...this.productRange,
      products: updatedProducts,
    };
  }

  addProduct(productToAdd: Product) {
    const updatedProducts = [...this.productRange.products, productToAdd];

    this.productRange = {
      ...this.productRange,
      products: updatedProducts,
    };
  }
}
