import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPresetModule } from '@ngx-formly/core/preset';
import { SharedFormWrapperUiCardModule } from '@ts/shared/form/wrapper/ui-card';

import { PromoUsagePresetProvider } from './promo-usage.preset';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlyPresetModule,
    FormlyModule.forChild({
      presets: [
        {
          name: 'promoUsage',
          config: new PromoUsagePresetProvider(),
        },
      ],
    }),
    SharedFormWrapperUiCardModule,
  ],
})
export class PromoUsageUiFormModule {}
