import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  TemplateRef,
} from '@angular/core';

import { LiabilityPagingService } from '@ts/finance/liability/list/data-access';
import { Liability } from '@ts/finance/liability/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-finance-liability-list-infinite',
  templateUrl: './finance-liability-list-infinite.component.html',
  styleUrls: ['./finance-liability-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceLiabilityListInfiniteComponent {
  @ContentChild('actionTemplate') actionTemplate!: TemplateRef<
    ActionTemplateContext<Liability>
  >;

  constructor(public liabilityPagingService: LiabilityPagingService) {}
}
