import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { FacilityZone } from '@ts/facility/shared/util-core';
import { FacilityZonePagingService } from '@ts/facility/zone/list/data-access';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-facility-zone-list-infinite',
  templateUrl: './facility-zone-list-infinite.component.html',
  styleUrls: ['./facility-zone-list-infinite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityZoneListInfiniteComponent {
  @Input() actionTemplate!: TemplateRef<ActionTemplateContext<FacilityZone>>;

  constructor(public facilityZonePagingService: FacilityZonePagingService) {}
}
