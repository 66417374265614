import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReturnToSupplierMentionUiComponent } from './return-to-supplier-mention-ui/return-to-supplier-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ReturnToSupplierMentionUiComponent],
  exports: [ReturnToSupplierMentionUiComponent],
})
export class ReturnToSupplierUiMentionModule {}
