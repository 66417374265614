import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { ProductUiTitlePriceModule } from '@ts/product/ui-title-price';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { ProductFormFieldComponent } from './product-form-field/product-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    ProductUiTitlePriceModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'product',
          component: ProductFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
  ],
  declarations: [ProductFormFieldComponent],
  exports: [ProductFormFieldComponent],
})
export class ProductFormFeatureFieldModule {}
