import { Injectable } from '@angular/core';

import { concat, map, Observable, of, switchMap } from 'rxjs';

import { ApiConsumerService } from '@ts/shared/api/data-access-api-consumer';
import { Paging, PagingRaw } from '@ts/shared/pagination/util-core';
import { QueryParamsService } from '@ts/shared/router/data-access-params';

export interface getPagingParams {
  apiService: ApiConsumerService;
  relativeUrl: string;
  queryParamsExtra?: Record<string, string>;
}

/**
 * Abstracts retrieving paginated data from server.
 *
 * The query parameters of the current URL is forwarded to the API.
 *
 * E.g., if the current page is www.bormadago.com/test?q=abc&page=5
 * and the relative url is test/relative, then it will call
 * www.bormadago.com/api/test/relative?q=abc&page=5
 */
@Injectable({
  providedIn: 'root',
})
export class EntityPagingService {
  constructor(private queryParamsService: QueryParamsService) {}

  getPaging$<T>({
    apiService,
    relativeUrl,
    queryParamsExtra = {},
  }: getPagingParams): Observable<Paging<T> | null> {
    return this.getRequestQueryParams$(queryParamsExtra).pipe(
      switchMap((queryParams) =>
        concat(
          of(null),
          apiService.get$<PagingRaw<T>>({
            relativeUrl,
            queryParams,
          }),
        ),
      ),
      map((pageRaw: PagingRaw<T> | null): Paging<T> | null => {
        if (!pageRaw) {
          return pageRaw;
        } else {
          return {
            meta: {
              page: pageRaw.page,
              numEntities: pageRaw.results.length,
              totalEntities: pageRaw.count,
              entitiesPerPage: pageRaw.items_per_page,
            },
            entities: pageRaw.results,
          };
        }
      }),
    );
  }

  private getRequestQueryParams$(
    queryParamsExtra: Record<string, string>,
  ): Observable<Record<string, string>> {
    // for paging, request params should default to query params
    return this.queryParamsService.getQueryParams$().pipe(
      map((queryParams) => {
        return {
          ...queryParams,
          ...queryParamsExtra,
        };
      }),
    );
  }
}
